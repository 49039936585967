/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, { memo, useEffect } from "react";

export interface IFormSelectProps {
  dataItem?: any[];
  title: string;
  onChangeValue?: (value) => void;
  selectedVal?: any;
  isStringArray?: boolean;
}

const FormSelect = (props: IFormSelectProps) => {
  const { selectedVal, isStringArray, onChangeValue, dataItem, title } = props;
  const [value, setValue] = React.useState<string>("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setValue(value);
  };
  useEffect(() => {
    if (!value) return;
    if (onChangeValue) onChangeValue(value);
  }, [value]);

  useEffect(() => {
    if (!selectedVal) return;
    setValue(selectedVal.id ? selectedVal.id : selectedVal);
  }, []);
  return (
    <Stack>
      <FormControl sx={{ width: "100%", mt: 1 }}>
        <InputLabel id="demo-multiple-checkbox-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={title}
          onChange={handleChange}
        >
          {dataItem?.map((val, i) => {
            return (
              <MenuItem value={isStringArray ? val : val.id} key={i}>
                {isStringArray ? val : val.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};
export default memo(FormSelect);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
