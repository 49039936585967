import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, Endpoint } from "api";
import { Param, BodyCUPackageSponsor, BodyCUSponsor } from "./reducer";

export const getPackageSponsor = createAsyncThunk(
  "packageSponsor/getPackageSponsor",
  async (params: Param) => {
    try {
      const response = await client.get(Endpoint.PACKAGESPONSOR, {
        params: params,
      });
      return response?.data.items;
    } catch (err) {}
  },
);

export const updatePackageSponsor = createAsyncThunk(
  "packageSponsor/updatePackageSponsor",
  async (body: BodyCUPackageSponsor) => {
    try {
      const param = paramsValue(body);
      const response = await client.put(
        `${Endpoint.PACKAGESPONSOR}/${body.id}`,
        param,
      );
      return response;
    } catch (err) {}
  },
);

export const createPackageSponsor = createAsyncThunk(
  "packageSponsor/createPackageSponsor",
  async (body: BodyCUPackageSponsor) => {
    try {
      const param = paramsValue(body);
      const response = await client.post(`${Endpoint.PACKAGESPONSOR}`, param);
      return response;
    } catch (err) {}
  },
);

export const deletePackageSponsor = createAsyncThunk(
  "packageSponsor/deletePackageSponsor",
  async (packageSponsorId: string) => {
    try {
      const response = await client.delete(
        `${Endpoint.PACKAGESPONSOR}/${packageSponsorId}`,
      );
      return response;
    } catch (err) {}
  },
);

export const getSponsor = createAsyncThunk(
  "sponsor/getSponsor",
  async (params: Param) => {
    try {
      const response = await client.get(Endpoint.SPONSOR, {
        params: params,
      });
      return response?.data.items;
    } catch (err) {}
  },
);

export const updateSponsor = createAsyncThunk(
  "sponsor/updateSponsor",
  async (body: BodyCUSponsor) => {
    try {
      const param = sponsorParamsValue(body);
      const response = await client.put(`${Endpoint.SPONSOR}/${body.id}`, param);
      return response;
    } catch (err) {}
  },
);

export const createSponsor = createAsyncThunk(
  "sponsor/createSponsor",
  async (body: BodyCUSponsor) => {
    try {
      const param = sponsorParamsValue(body);
      const response = await client.post(`${Endpoint.SPONSOR}`, param);
      return response;
    } catch (err) {}
  },
);

export const deleteSponsor = createAsyncThunk(
  "sponsor/deleteSponsor",
  async (sponsorId: string) => {
    try {
      const response = await client.delete(`${Endpoint.SPONSOR}/${sponsorId}`);
      return response;
    } catch (err) {}
  },
);
// const removeId = ({ id, ...rest }: ParamTournament) => rest;
export const paramsValue = (body: BodyCUPackageSponsor) => {
  return {
    name: body.name,
    speakingSlot: Number(body.speakingSlot),
    isMentionOnYoutube: body.isMentionOnYoutube,
    isBannerLeaderboard: body.isBannerLeaderboard,
    isLogoInTrack: body.isLogoInTrack,
    isTwitterAnnouncement: body.isTwitterAnnouncement,
    isHighlightPost: body.isHighlightPost,
    logoSizeOnWeb: Number(body.logoSizeOnWeb),
    logoSizeOnOfficial: Number(body.logoSizeOnOfficial),
    logoSizeOnMedia: Number(body.logoSizeOnMedia),
    isNewsletterEmail: body.isNewsletterEmail,
    isMentionedInServices: body.isMentionedInServices,
    isCertificateOfSponsorship: body.isCertificateOfSponsorship,
    isBecomeLongterm: body.isBecomeLongterm,
  };
};
export const sponsorParamsValue = (body: BodyCUSponsor) => {
  return {
    name: body.name,
    description: body.description,
    packageSponsorId: body.packageSponsorId,
    tournamentId: body.tournamentId,
    logo: body.logo,
    social: body.social,
  };
};