import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, Endpoint } from "api";
import { HttpStatusCode } from "constant/enum";
import { BodyUrlRacetrack, Param } from "./reducer";

export const postRacetrack = createAsyncThunk(
  "racetrack/postRacetrack",
  async (bodyvalue: BodyUrlRacetrack) => {
    const params = paramsValue(bodyvalue);
    try {
      const resp = await client.post(Endpoint.RACETRACK, params);
      if (resp.status === HttpStatusCode.CREATED) {
        return resp?.data;
      }
    } catch (err) {}
  },
);

export const putRacetrack = createAsyncThunk(
  "racetrack/putRacetrack",
  async (param: BodyUrlRacetrack) => {
    try {
      const params = paramsValue(param);

      const response = await client.put(
        `${Endpoint.RACETRACK}/${param._id}`,
        params,
      );
      if (response && (response.status === 200 || response.status === 201)) {
        return "success";
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  },
);
export const getRacetrack = createAsyncThunk(
  "racetrack/getRacetrack",
  async (param?: Param) => {
    try {
      const response = await client.get(Endpoint.RACETRACK, {
        params: param,
      });
      return response?.data?.items;
    } catch (err) {}
  },
);

export const getCollectionModel = createAsyncThunk(
  "racetrack/getCollectionModel",
  async () => {
    try {
      const response = await client.get(Endpoint.COLLECTION_MODEL);
      return response?.data;
    } catch (err) {}
  },
);

export const deleteRacetrack = createAsyncThunk(
  "racetrack/deleteRacetrack",
  async (itemId: string) => {
    try {
      const response = await client.delete(`${Endpoint.RACETRACK}/${itemId}`);
      return response?.data;
    } catch (err) {}
  },
);

export const paramsValue = (body: BodyUrlRacetrack) => {
  return {
    name: body.name,
    description: body.description,
    image: body.image,
    background: body.background,
    timeReq: Number(body.timeReq),
    laps: Number(body.laps),
    models: body.models,
  };
};
