import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, Endpoint } from "api";
import { ParamUpdateMetadata } from "./reducer";

export const getMetadata = createAsyncThunk(
  "specification/getMetadata",
  async () => {
    try {
      const response = await client.get(Endpoint.METADATA);
      return response?.data;
    } catch (err) {}
  },
);

export const updateMetadata = createAsyncThunk(
  "specification/updateMetadata",
  async (body:ParamUpdateMetadata) => {
    try {
      const param= {
        topSpeed: Number(body.topSpeed),
        acceleration: Number(body.acceleration),
        handling: Number(body.handling),
        classType: body.classType,
        fuel: Number(body.fuel)
      }
      const response = await client.put(`${Endpoint.UDMETADATA}/${body.collectionId}`,param);
      return response;
    } catch (err) {}
  },
);