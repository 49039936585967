/* eslint-disable @typescript-eslint/no-explicit-any */
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import EventIcon from "@mui/icons-material/Event";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import HouseIcon from "@mui/icons-material/House";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import Link from "components/Link";
import { useRouter } from "next/router";
import { memo } from "react";
import AutofpsSelectIcon from "@mui/icons-material/AutofpsSelect";

interface Menu {
  content: string;
  url: string;
  icon?: React.ReactNode;
  wrapperNav?: Array<any>;
}
export const ICON_MENU_SELECT = "icon-menu-style";

const MENU_LIST: Menu[] = [
  {
    content: "Home",
    url: "/",
    icon: <HouseIcon className={ICON_MENU_SELECT} />,
  },

  {
    content: "Racetrack",
    url: "/racetrack",
    icon: <SettingsSuggestIcon className={ICON_MENU_SELECT} />,
  },
  {
    content: "Tournament",
    url: "/tournaments",
    icon: <EventIcon className={ICON_MENU_SELECT} />,
  },
  {
    content: "Flash Event",
    url: "/flashevent",
    icon: <FlashAutoIcon className={ICON_MENU_SELECT} />,
  },
  {
    content: "Staking",
    url: "/staking",
    icon: <CurrencyExchangeIcon className={ICON_MENU_SELECT} />,
  },
  {
    content: "Sponsor",
    url: "/sponsor",
    icon: <SupervisedUserCircleIcon className={ICON_MENU_SELECT} />,
    wrapperNav: [
      {
        content: "Sponsor",
        url: "/sponsor",
      },
      {
        content: "Package",
        url: "/sponsor/packagesponsor",
      },
    ],
  },
  {
    content: "Config",
    url: "/config",
    icon: <SettingsApplicationsIcon className={ICON_MENU_SELECT} />,
    wrapperNav: [
      {
        content: "Game config",
        url: "/config/game",
      },
      {
        content: "Marketplace",
        url: "/config/marketplace",
      },
      {
        content: "Econ Config",
        url: "/config/econ",
      },
      {
        content: "Car Specifications",
        url: "/config/carspecifications",
      },
    ],
  },
];

export const Menu = (props: StackProps) => {
  const router = useRouter();

  return (
    <Stack
      py={0}
      width={300}
      height="100vh"
      flex={1}
      zIndex={4}
      overflow="hidden"
      sx={{ backgroundColor: "red" }}
      {...props}
    >
      <Stack
        pl={5}
        py={1}
        className="header"
        borderRight="4px solid"
        borderBottom="4px solid "
        flex={0.1}
        minHeight={100}
        justifyContent="center"
        sx={{
          backgroundColor: "#1e1e1e",
          borderImageSource:
            "linear-gradient(to top, rgba(255,255,255,.3), rgba(255,255,255,.3))",
          borderImageSlice: 1,
          borderWidth: 2,
        }}
      >
        <Typography
          textAlign="justify"
          fontSize={30}
          textTransform="uppercase"
          className="header-logo"
          fontWeight="800"
          fontFamily="Coda"
        >
          RaceFi
        </Typography>
      </Stack>
      <Stack
        borderRight="1px solid rgba(255,255,255,.3)"
        height="100%"
        flex={0.9}
        px={1}
        sx={{ backgroundColor: "#1e1e1e" }}
      >
        <Stack className="menu">
          {MENU_LIST.map((item, i) => {
            if (item.wrapperNav) {
              return (
                <Box
                  sx={{
                    "& .MuiAccordion-root": {
                      borderColor: "blue",
                      color: "text.secondary",

                      "& .MuiAccordionSummary-root": {
                        bgcolor: "transparent",
                        flexDirection: "row",
                      },
                      "& .MuiAccordionDetails-root": {
                        borderColor: "red",
                      },
                      "& .Mui-expanded": {
                        color: "primary.dark",
                      },
                    },
                  }}
                >
                  <Accordion
                    sx={{
                      backgroundColor: "transparent",
                      backgroundImage: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon className={ICON_MENU_SELECT} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        padding: 0,
                        margin: 0,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Stack mx={1.5}>{item.icon}</Stack>
                        <Typography
                          variant="overline"
                          py={1.5}
                          textTransform="none"
                        >
                          {item.content}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    {item.wrapperNav.map((nav, index) => {
                      return (
                        <AccordionDetails key={index} sx={{ ml: 4 }}>
                          <Link
                            key={nav.url}
                            color={
                              nav.url === router.pathname
                                ? "primary.dark"
                                : "text.secondary"
                            }
                            sx={{
                              display: "flex",
                              direction: "row",
                              alignItems: "center",
                              textDecoration: "none",
                              "&:hover": {
                                color: "text.primary",
                                textTransform: "none",
                              },
                            }}
                            href={nav.url}
                          >
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              textTransform="none"
                            >
                              {nav.content}
                            </Typography>
                          </Link>
                        </AccordionDetails>
                      );
                    })}
                  </Accordion>
                </Box>
              );
            } else {
              return (
                <Link
                  key={item.url}
                  color={
                    item.url === router.pathname
                      ? "primary.dark"
                      : "text.secondary"
                  }
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                    textDecoration: "none",
                    py: 1,
                    "&:hover": {
                      color: "text.primary",
                      textTransform: "none",
                    },
                    [`&.${ICON_MENU_SELECT}`]: {
                      margin: "5px 10px",
                    },
                  }}
                  href={item.url}
                >
                  <Stack mx={1.5}>{item.icon}</Stack>
                  <Typography
                    variant="subtitle1"
                    py={1.5}
                    color="inherit"
                    textTransform="none"
                  >
                    {item.content}
                  </Typography>
                </Link>
              );
            }
          })}
        </Stack>
      </Stack>
      {/* <Box
        component="img"
        src={"/images/bg-menu.png"}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      /> */}
    </Stack>
  );
};

export default memo(Menu);
