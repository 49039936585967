export type StakeV2 = {
  version: "0.1.0";
  name: "stake_v2";
  instructions: [
    {
      name: "createStakePool";
      accounts: [
        {
          name: "pool";
          isMut: true;
          isSigner: true;
        },
        {
          name: "creator";
          isMut: true;
          isSigner: true;
        },
        {
          name: "creatorTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "stakeToken";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "escrowedTokenBump";
          type: "u8";
        },
        {
          name: "minStake";
          type: "u64";
        },
        {
          name: "maxStake";
          type: "u64";
        },
        {
          name: "maxPool";
          type: "u64";
        },
        {
          name: "duration";
          type: "u64";
        },
        {
          name: "apr";
          type: "u64";
        },
        {
          name: "startTimeStake";
          type: "u64";
        },
        {
          name: "startValueDate";
          type: "u64";
        },
      ];
    },
    {
      name: "closePool";
      accounts: [
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "authorize";
          isMut: true;
          isSigner: true;
        },
        {
          name: "authorizeTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "stake";
      accounts: [
        {
          name: "stakeInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        },
      ];
    },
    {
      name: "unStake";
      accounts: [
        {
          name: "stakeInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
      returns: "u64";
    },
    {
      name: "getClaimable";
      accounts: [
        {
          name: "stakeInfo";
          isMut: false;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "time";
          type: "u64";
        },
      ];
      returns: "u64";
    },
    {
      name: "claim";
      accounts: [
        {
          name: "stakeInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
      returns: "u64";
    },
    {
      name: "withdrawReward";
      accounts: [
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "authorize";
          isMut: true;
          isSigner: true;
        },
        {
          name: "authorizeTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "updatePool";
      accounts: [
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "authorize";
          isMut: true;
          isSigner: true;
        },
      ];
      args: [
        {
          name: "minStake";
          type: "u64";
        },
        {
          name: "maxStake";
          type: "u64";
        },
        {
          name: "startValueDate";
          type: "u64";
        },
      ];
    },
  ];
  accounts: [
    {
      name: "stakeInfo";
      type: {
        kind: "struct";
        fields: [
          {
            name: "pool";
            type: "publicKey";
          },
          {
            name: "user";
            type: "publicKey";
          },
          {
            name: "time";
            type: "u64";
          },
          {
            name: "amount";
            type: "u64";
          },
          {
            name: "claimed";
            type: "u64";
          },
        ];
      };
    },
    {
      name: "stakePool";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authorize";
            type: "publicKey";
          },
          {
            name: "stakeToken";
            type: "publicKey";
          },
          {
            name: "minStake";
            type: "u64";
          },
          {
            name: "maxStake";
            type: "u64";
          },
          {
            name: "maxPool";
            type: "u64";
          },
          {
            name: "totalStaked";
            type: "u64";
          },
          {
            name: "stakeholder";
            type: "u64";
          },
          {
            name: "duration";
            type: "u64";
          },
          {
            name: "apr";
            type: "u64";
          },
          {
            name: "startTimeStake";
            type: "u64";
          },
          {
            name: "startValueDate";
            type: "u64";
          },
          {
            name: "escrowedTokenBump";
            type: "u8";
          },
        ];
      };
    },
  ];
  errors: [
    {
      code: 6000;
      name: "NothingToClaim";
      msg: "NothingToClaim";
    },
  ];
};

export const IDL: StakeV2 = {
  version: "0.1.0",
  name: "stake_v2",
  instructions: [
    {
      name: "createStakePool",
      accounts: [
        {
          name: "pool",
          isMut: true,
          isSigner: true,
        },
        {
          name: "creator",
          isMut: true,
          isSigner: true,
        },
        {
          name: "creatorTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "stakeToken",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "escrowedTokenBump",
          type: "u8",
        },
        {
          name: "minStake",
          type: "u64",
        },
        {
          name: "maxStake",
          type: "u64",
        },
        {
          name: "maxPool",
          type: "u64",
        },
        {
          name: "duration",
          type: "u64",
        },
        {
          name: "apr",
          type: "u64",
        },
        {
          name: "startTimeStake",
          type: "u64",
        },
        {
          name: "startValueDate",
          type: "u64",
        },
      ],
    },
    {
      name: "closePool",
      accounts: [
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "authorize",
          isMut: true,
          isSigner: true,
        },
        {
          name: "authorizeTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "stake",
      accounts: [
        {
          name: "stakeInfo",
          isMut: true,
          isSigner: false,
        },
        {
          name: "user",
          isMut: true,
          isSigner: true,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amount",
          type: "u64",
        },
      ],
    },
    {
      name: "unStake",
      accounts: [
        {
          name: "stakeInfo",
          isMut: true,
          isSigner: false,
        },
        {
          name: "user",
          isMut: true,
          isSigner: true,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
      returns: "u64",
    },
    {
      name: "getClaimable",
      accounts: [
        {
          name: "stakeInfo",
          isMut: false,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "time",
          type: "u64",
        },
      ],
      returns: "u64",
    },
    {
      name: "claim",
      accounts: [
        {
          name: "stakeInfo",
          isMut: true,
          isSigner: false,
        },
        {
          name: "user",
          isMut: true,
          isSigner: true,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
      returns: "u64",
    },
    {
      name: "withdrawReward",
      accounts: [
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "authorize",
          isMut: true,
          isSigner: true,
        },
        {
          name: "authorizeTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "updatePool",
      accounts: [
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "authorize",
          isMut: true,
          isSigner: true,
        },
      ],
      args: [
        {
          name: "minStake",
          type: "u64",
        },
        {
          name: "maxStake",
          type: "u64",
        },
        {
          name: "startValueDate",
          type: "u64",
        },
      ],
    },
  ],
  accounts: [
    {
      name: "stakeInfo",
      type: {
        kind: "struct",
        fields: [
          {
            name: "pool",
            type: "publicKey",
          },
          {
            name: "user",
            type: "publicKey",
          },
          {
            name: "time",
            type: "u64",
          },
          {
            name: "amount",
            type: "u64",
          },
          {
            name: "claimed",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "stakePool",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authorize",
            type: "publicKey",
          },
          {
            name: "stakeToken",
            type: "publicKey",
          },
          {
            name: "minStake",
            type: "u64",
          },
          {
            name: "maxStake",
            type: "u64",
          },
          {
            name: "maxPool",
            type: "u64",
          },
          {
            name: "totalStaked",
            type: "u64",
          },
          {
            name: "stakeholder",
            type: "u64",
          },
          {
            name: "duration",
            type: "u64",
          },
          {
            name: "apr",
            type: "u64",
          },
          {
            name: "startTimeStake",
            type: "u64",
          },
          {
            name: "startValueDate",
            type: "u64",
          },
          {
            name: "escrowedTokenBump",
            type: "u8",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "NothingToClaim",
      msg: "NothingToClaim",
    },
  ],
};
