import { Wallet } from "@project-serum/anchor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, Endpoint } from "api";
import { getMyProgram } from "utils/configMarket";
import { putGameConfigParam } from "./reducer";

export const getGameConfig = createAsyncThunk(
  "gameConfig/getGameConfig",
  async () => {
    try {
      const response = await client.get(Endpoint.GAME_CONFIG);
      return response?.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const putGameConfig = createAsyncThunk(
  "gameConfig/putGameConfig",
  async (param: putGameConfigParam) => {
    try {
      const response = await client.post(Endpoint.GAME_CONFIG, param);
      if (response.status === 200 || response.status === 201) {
        return "success";
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  },
);
