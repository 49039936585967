import { Cluster, clusterApiUrl } from "@solana/web3.js";

export const API_URL = process.env.API_URL as string;
export const SENTRY_DSN = process.env.SENTRY_DSN as string;
export const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID as string;
export const SOCKET_URL = process.env.SOCKET_URL as string;
export const OG_IMAGE = process.env.OG_IMAGE as string;

export const THEME_KEY = "theme";
export const DARK_THEME_MEDIA_SYSTEM = "(prefers-color-scheme: dark)";

export const STAKE_V2_CONTRACT = process.env.STAKE_V2_CONTRACT as string;
export const STAKING_RECEIVE_SOL_CONTRACT = process.env
  .STAKING_RECEIVE_SOL_CONTRACT as string;
export const MARKET_CONTRACT = process.env.MARKET_CONTRACT as string;
export const RACEFI_TOKEN = process.env.RACEFI_TOKEN as string;
export const ECON_NFT_CONTRACT = process.env.ECON_NFT_CONTRACT as string;
export const ECON_SPL_CONTRACT = process.env.ECON_SPL_CONTRACT as string;
// Config app
export const MAX_WIDTH_APP = 1920;
export const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const DEBOUNCE_SCROLL = 250; //ms
export const TIMESTAMP_DAY = 24 * 60 * 60 * 1000;

export const FORMAT_DATE_TIME = "hh:mm yyyy/MM/dd";
export const FORMAT_TIME = "hh:mm";
export const NOT_HAVE_VALUE = "--";

export const HOME_PATH = "/";
export const SIGNIN_PATH = "/signin";

// Config multiple language
export const ENGLISH_LANGUAGE = "en";
export const VIETNAMESE_LANGUAGE = "vi";
export const DEFAULT_LANGUAGE = ENGLISH_LANGUAGE;
export const DEFAULT_NAMESPACE = "common";
export const NS_HOME = "home";
export const SOLANA_NETWORK = process.env.SOLANA_NETWORK as string;
export const SOLANA_ENDPOINT = process.env.SOLANA_ENDPOINT as string;
