import { createSlice } from "@reduxjs/toolkit";
import { Column } from "components/Table";
import { createTournament, deleteTournament, getTournament } from "./actions";

export interface TournamentItem {
  id: string;
  name: string;
  description: string;
  totalPrize: number;
  structurePrize: string;
  startTimeReg: Date;
  endTimeReg: Date;
  startRace: Date;
  endRace: Date;
  term: string;
  background: string;
  status: string;
  racetrack: string;
  laps: number;
  class: string[];
  totalParticipants?: number;
  createdAt?: Date;
  updatedAt?: Date;
}
export enum Status {
  INACTIVE = 1,
  ACTIVE = 2,
}
export interface ParamTournament {
  name: string;
  description: string;
  totalPrize: number;
  structurePrize: string;
  startTimeReg: Date;
  endTimeReg: Date;
  startRace: Date;
  endRace: Date;
  term: string;
  background: string;
  status: Status;
  racetrack: string;
  laps: number;
  class: string[];
  id?: string;
}

export interface Form {
  key: string;
  label: string;
  type: string;
  selectMultiple?: boolean;
}
export interface ParamGetTournament {
  pageSize: number;
  pageIndex: number;
  status?: string;
  search?: string;
}
export interface TournamentState {
  tournamentItems: TournamentItem[];
  dataTabHeader: Column[];
  formConfig: Form[];
  pagingParam: ParamGetTournament;
  classModel: string[];
}

const initialState: TournamentState = {
  tournamentItems: [],
  pagingParam: {
    pageSize: 1000,
    pageIndex: 1,
  },
  classModel: ["S", "A", "B", "C"],
  dataTabHeader: [
    {
      id: "name",
      label: "Name",
      minWidth: "15%",
    },
    {
      id: "totalPrize",
      label: "Total Prize (RaceFi)",
      minWidth: "15%",
    },
    {
      id: "totalParticipants",
      label: "Total Participants",
      minWidth: "15%",
    },
    {
      id: "startTimeReg",
      label: "Start Time Reg",
      minWidth: "15%",
    },
    {
      id: "startRace",
      label: "Start Race",
      minWidth: "15%",
    },
    {
      id: "status",
      label: "Status",
      minWidth: "15%",
    },
    {
      id: "action",
      label: "Action",
      minWidth: "10%",
    },
  ],
  formConfig: [
    {
      key: "name",
      label: "Name",
      type: "text",
    },
    {
      key: "description",
      label: "Description",
      type: "text",
    },
    {
      key: "totalPrize",
      label: "Total Prize",
      type: "number",
    },
    {
      key: "totalParticipants",
      label: "Total Participants",
      type: "number",
    },
    {
      key: "structurePrize",
      label: "Structure Prize",
      type: "text",
    },
    {
      key: "racetrack",
      label: "Race Track",
      type: "dropdown",
      selectMultiple: false,
    },
    {
      key: "laps",
      label: "Laps",
      type: "number",
    },
    {
      key: "class",
      label: "Class",
      type: "dropdown",
      selectMultiple: true,
    },
    {
      key: "startTimeReg",
      label: "Start Time Reg",
      type: "picktime",
    },
    {
      key: "endTimeReg",
      label: "End Time Reg",
      type: "picktime",
    },
    {
      key: "startRace",
      label: "Start Race",
      type: "picktime",
    },
    {
      key: "endRace",
      label: "End Race",
      type: "picktime",
    },
    {
      key: "term",
      label: "Term",
      type: "text",
    },
    {
      key: "background",
      label: "Background",
      type: "files",
    },
    {
      key: "status",
      label: "Status",
      type: "text",
    },
  ],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTournament.fulfilled, (state, action) => {
        if (action.payload) {
          state.tournamentItems = action.payload ?? [];
        }
      })
      .addCase(createTournament.fulfilled, (state, action) => {
        if (action.payload) {
          state.tournamentItems.unshift(action.payload);
        }
      });
  },
});

export const { fieldChange } = appSlice.actions;

export default appSlice.reducer;
