/* eslint-disable prefer-const */
import React, { memo, useRef, useState, useEffect, useMemo } from "react";
import { Box, Slider, Stack, Typography } from "@mui/material";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  PercentCrop,
  ReactCropProps,
} from "react-image-crop";
import ModalLayout from "./ModalLayout";

type CropImageProps = {
  file: File;
  onClose: () => void;
  aspect: number;
  onResized: (data: File) => void;
} & Omit<ReactCropProps, "onChange">;

const CropImage = (props: CropImageProps) => {
  const { file, onClose, aspect, onResized, ...rest } = props;

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);

  const previewImage = useMemo(() => URL.createObjectURL(file), [file]);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const onChangeZoom = (_, value: number | number[]) => {
    setScale((value as number) / 100);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
        );
      }
    },
    200,
    [completedCrop, scale],
  );

  const onApply = () => {
    if (!previewCanvasRef.current) return;
    const canvasURL = previewCanvasRef.current.toDataURL();
    onResized(dataURLtoFile(canvasURL, file.name, file.type));
    onClose();
  };

  const onChangeCrop = (_, percentCrop: PercentCrop) => {
    setCrop(percentCrop);
  };

  const onComplete = (crop: PixelCrop) => {
    setCompletedCrop(crop);
  };

  return (
    <ModalLayout open onClose={onClose} onApply={onApply}>
      <>
        <ReactCrop
          {...rest}
          crop={crop}
          onChange={onChangeCrop}
          onComplete={onComplete}
          aspect={aspect}
        >
          <Box
            component="img"
            ref={imgRef}
            alt="Crop me"
            src={previewImage}
            style={{ transform: `scale(${scale})` }}
            width="auto"
            height="calc(100vh - 40px*2 - 62px - 89px - 40px - 30px)!important" // 40px * 2: paddingVertical, 62px: height of header, 89px: height of bottom, 24px: paddingVertical
            onLoad={onImageLoad}
          />
        </ReactCrop>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">-</Typography>
          <Slider
            sx={{
              width: 300,
            }}
            defaultValue={100}
            getAriaValueText={getValueText}
            valueLabelDisplay="auto"
            onChange={onChangeZoom}
            step={10}
            min={50}
            max={200}
          />
          <Typography variant="body2">+</Typography>
        </Stack>
        {Boolean(completedCrop) && (
          <Box
            component="canvas"
            display="none"
            ref={previewCanvasRef}
            style={{
              objectFit: "contain",
              width: (completedCrop as PixelCrop).width,
              height: (completedCrop as PixelCrop).height,
            }}
          />
        )}
      </>
    </ModalLayout>
  );
};

export default memo(CropImage);

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};

const getValueText = (value: number) => {
  return `${value}%`;
};

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDebounceEffect = (fn: () => void, waitTime: number, deps?: any) => {
  useEffect(() => {
    const t = setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);
};

const dataURLtoFile = (dataUrl: string, filename: string, mimeType: string) => {
  let arr = dataUrl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mimeType });
};
