import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyProgram } from "utils/econ_nft_spl";
import { EconNft, IDL as EconNftIdl } from "assets/programs/econ_nft";
import { EconSpl, IDL as EconSplIdl } from "assets/programs/econ_spl";
import { ECON_NFT_CONTRACT, ECON_SPL_CONTRACT } from "constant";
import * as anchor from "@project-serum/anchor";
import { Program, Wallet } from "@project-serum/anchor";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";

export const onGetConfigNft = createAsyncThunk(
  "econconfig/onGetConfigNft",
  async (wallet: Wallet) => {
    console.log("🚀 wallet", wallet);

    try {
      const program = getMyProgram(
        wallet,
        ECON_NFT_CONTRACT,
        EconNftIdl,
      ) as anchor.Program<EconNft>;

      const preConfigNft = await program.account.econNftConfig.all();
      console.log("🚀 preConfigNft", preConfigNft[0]);
      return preConfigNft[0];
    } catch (err) {
      console.error(err);
    }
  },
);

export const onGetConfigSpl = createAsyncThunk(
  "econconfig/onGetConfigSpl",
  async (wallet: Wallet) => {
    try {
      const program = getMyProgram(
        wallet,
        ECON_SPL_CONTRACT,
        EconSplIdl,
      ) as anchor.Program<EconSpl>;
      const preConfigSpl = await program.account.econSplConfig.all();
      return preConfigSpl[0];
    } catch (err) {
      console.error(err);
    }
  },
);
