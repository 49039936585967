/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Typography } from "@mui/material";
import Link, { LinkProps } from "@mui/material/Link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { memo, useMemo } from "react";
import Breadcrumbs from "nextjs-breadcrumbs";
import Styled from "./index.module.scss";

type RoundterLinkLayoutProps = {};

const RoundterLinkLayout = (props: RoundterLinkLayoutProps) => {
  return (
    <Stack className={Styled["wrapper-container"]}>
      <Breadcrumbs
        rootLabel="Home"
        useDefaultStyle
        activeItemClassName={Styled["brActive"]}
      />
    </Stack>
  );
};

export default memo(RoundterLinkLayout);
