import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { client, Endpoint } from "api";
import { HttpStatusCode } from "constant/enum";
import { AuthData, Snackbar, UploadBody } from "./reducer";

export const updateSnackbar = createAction<Snackbar | null>(
  "application/updateSnackbar",
);

export const updateAuth = createAction<AuthData>("app/updateAuth");

export const SignatureMsg = createAsyncThunk(
  "application/SignatureMsg",
  async (address: string) => {
    const response = await client.post(Endpoint.AUTH, address);
    return response?.data;
  },
);

export const Auth = createAsyncThunk(
  "application/Auth",
  async (address: string) => {
    const response = await client.post(
      `${Endpoint.AUTH}/signature-message`,
      address,
    );
    try {
      return response?.data;
    } catch (err) {}
  },
);

export const presignedUploadFile = createAsyncThunk(
  "application/presignedUrl",
  async ({
    uploadBody,
    fieldUpload,
  }: {
    uploadBody: File;
    fieldUpload: string;
  }) => {
    const response = await client.post(Endpoint.UPLOAD, uploadBody);
    if (response.status === HttpStatusCode.CREATED) {
      const myHeaders = new Headers({
        "Content-Type": uploadBody.type,
        "x-ms-version": "2015-02-21",
        "x-ms-blob-type": "BlockBlob",
      });
      const responseUpload = await fetch(response.data.presignedUrl, {
        method: "PUT",
        headers: myHeaders,
        body: uploadBody,
      });

      if (responseUpload.status === HttpStatusCode.CREATED) {
        return {
          data: response?.data.url,
          fieldUpload: fieldUpload,
        };
      } else {
        throw Error("Error");
      }
    } else {
      throw Error("Error");
    }
  },
);
