/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect } from "react";
import {
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

type DropdownLayoutProps = {
  dataItems: any[];
  label: string;
  onChangeValue: (value) => void;
  selectedItem?: any[];
  isStringArray?: boolean;
};

const DropdownLayout = (props: DropdownLayoutProps) => {
  const { dataItems, label, onChangeValue, selectedItem, isStringArray } =
    props;
  const [value, setValue] = React.useState<any[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event;
    setValue(typeof value === "string" ? value.split(", ") : value);
  };

  useEffect(() => {
    if (!value) return;
    if (onChangeValue) onChangeValue(value);
  }, [value]);

  useEffect(() => {
    if (
      selectedItem &&
      selectedItem.length !== value.length &&
      value.length === 0
    )
      setValue(
        selectedItem[0].id
          ? selectedItem.map((item) => item.id)
          : selectedItem.map((item) => item),
      );
  }, []);
  return (
    <Stack>
      <FormControl sx={{ width: "100%", mt: 1 }}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) =>
            isStringArray
              ? renderValueIsStringArr(dataItems, selected)
              : renderValue(dataItems, selected)
          }
          MenuProps={MenuProps}
        >
          {dataItems.map((item, index) => (
            <MenuItem key={index} value={isStringArray ? item : item?.id}>
              <Checkbox
                checked={value.indexOf(isStringArray ? item : item.id) > -1}
              />
              <ListItemText primary={isStringArray ? item : item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
export default memo(DropdownLayout);

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const renderValueIsStringArr = (data: any[], modelIds: string[]) => {
  return modelIds.reduce((outText, modelId) => {
    return (outText +=
      (outText.length ? ", " : "") + data.find((model) => model === modelId));
  }, "");
};

const renderValue = (data: any[], modelIds: string[]) => {
  return modelIds.reduce((outText, modelId) => {
    return (outText +=
      (outText.length ? ", " : "") +
      data.find((model) => model.id === modelId)?.name);
  }, "");
};
