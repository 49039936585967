import { createAsyncThunk } from "@reduxjs/toolkit";
import { Wallet } from "@project-serum/anchor";
import { StakingPoolType } from ".";
import { getRaceFiRaceFiPools, getRaceFiSolanaPools } from "utils/staking";

export const getPools = createAsyncThunk(
  "staking/getPools",
  async ({
    wallet,
    stakingType,
  }: {
    wallet: unknown;
    stakingType: StakingPoolType;
  }) => {
    try {
      let pools;
      if (stakingType === StakingPoolType.RACEFI) {
        pools = await getRaceFiRaceFiPools(wallet as unknown as Wallet);
      } else {
        pools = await getRaceFiSolanaPools(wallet as unknown as Wallet);
      }
      return pools;
    } catch (error) {
      throw error;
    }
  },
);
