import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

interface UIProps {
  children?: ReactNode;
  anchorDirection: "left" | "top" | "right" | "bottom";
  stateModel?: boolean;
  title?: string;
  onHandleClick?: () => void;
  typeInput?: string;
}

const TemporaryDrawer = (props: UIProps) => {
  const {
    children,
    anchorDirection,
    stateModel,
    title,
    onHandleClick,
    typeInput,
  } = props;
  const [state, setState] = React.useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);

      if (onHandleClick) onHandleClick();
    };
  RemoveRedEyeIcon;
  return (
    <React.Fragment>
      {typeInput === "update" ? (
        <IconButton
          aria-label="update"
          onClick={toggleDrawer(true)}
          sx={{ width: "fit-content" }}
        >
          <DriveFileRenameOutlineIcon color="info" />
        </IconButton>
      ) : typeInput === "detail" ? (
        <IconButton
          aria-label="update"
          onClick={toggleDrawer(true)}
          sx={{ width: "fit-content" }}
        >
          <RemoveRedEyeIcon sx={{ color: "white" }} />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          color="info"
          onClick={toggleDrawer(true)}
          sx={{}}
        >
          {title}
        </Button>
      )}
      <Drawer
        anchor={anchorDirection}
        open={state}
        onClose={toggleDrawer(false)}
        sx={{}}
      >
        <Box
          sx={{
            width: 500,
            height: "200%",
            position: "relative",
            color: "text.primary",
            backgroundColor: "background.paper",
          }}
          role="presentation"
          // onClick={toggleDrawer(false)}
          // onKeyDown={toggleDrawer(false)}
        >
          <Stack
            sx={{
              width: "100%",
              py: 2,
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              backgroundColor: "background.paper",
            }}
          >
            <Typography
              variant="h6"
              py={1.5}
              fontWeight={700}
              letterSpacing={0.5}
              textTransform="none"
            >
              {title}
            </Typography>
            <IconButton
              onClick={toggleDrawer(false)}
              sx={{
                width: 35,
                position: "absolute",
                right: 20,
                alignSeft: "center",
                justifySeft: "center",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          {children}
          {/* <Divider /> */}
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default TemporaryDrawer;
