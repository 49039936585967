import React, { memo } from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  ModalProps,
} from "@mui/material";

type ModalLayoutProps = {
  children: React.ReactNode;
  onApply: () => void;
} & ModalProps;

const ModalLayout = (props: ModalLayoutProps) => {
  const { children, onClose, onApply, ...rest } = props;

  const onReset = () => {
    onClose && onClose({}, "backdropClick");
  };

  return (
    <Modal onClose={onClose} {...rest}>
      <Box component="div" sx={sxConfigs.paper}>
        <Stack
          sx={sxConfigs.header}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            fontWeight={500}
            variant="subtitle1"
            fontFamily="Mashine"
            lineHeight={1.5}
          >
            Resize Image
          </Typography>
        </Stack>
        <Stack
          sx={sxConfigs.main}
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          py={2.5}
        >
          {children}
        </Stack>
        <Stack
          sx={sxConfigs.bottom}
          spacing={3 / 2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button sx={sxConfigs.buttonAction} onClick={onReset}>
            <Typography color="text.primary" fontFamily="Mashine">
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              ...sxConfigs.buttonAction,
              backgroundColor: "primary.dark",
              "&:hover": {
                backgroundColor: "secondary.dark",
              },
            }}
            onClick={onApply}
          >
            <Typography color="text.primary" fontFamily="Mashine">
              Apply
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default memo(ModalLayout);

const sxConfigs = {
  buttonModal: {
    border: "1px solid",
    borderColor: "grey.400",
    borderRadius: 1,
    height: 48,
    minWidth: 120,
    width: { xs: "fit-content", md: "initial" },
  },
  paper: {
    width: "100%",
    maxWidth: "70vw",
    borderRadius: 1,
    overflow: "hidden",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "background.default",
    outline: "none",
  },
  header: {
    position: "sticky",
    top: 0,
    py: 2,
    px: 3,
    backgroundColor: "primary.dark",
  },
  bottom: {
    position: "sticky",
    bottom: 0,
    p: 3,
    borderTop: "1px solid",
    borderTopColor: "divider",
  },
  buttonAction: {
    flex: 1,
    maxWidth: 180,
    border: "1px solid",
    borderColor: "grey.400",
    borderRadius: 1,
    height: 40,
  },
  main: {
    height: "calc(100vh - 40px*2 - 62px - 89px)", // 40px * 2: paddingVertical, 62px: height of header, 89px: height of bottom
  },
  buttonClose: {
    position: "absolute",
    right: 20,
    top: 12,
  },
};
