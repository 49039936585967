import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, Endpoint } from "api";
import { ParamGetFlashEvent, BodyCUFlashEvent } from "./reducer";

export const getFlashEvents = createAsyncThunk(
  "flashEvents/getFlashEvents",
  async (params: ParamGetFlashEvent) => {
    try {
      const response = await client.get(Endpoint.FLASH_EVENTS, {
        params: params,
      });
      return response?.data.items;
    } catch (err) {}
  },
);
export const updateFlashEvents = createAsyncThunk(
  "flashEvents/updateMetadata",
  async (body: BodyCUFlashEvent) => {
    try {
      const param = paramsValue(body);
      const response = await client.put(
        `${Endpoint.FLASH_EVENTS}/${body.id}`,
        param,
      );
      return response;
    } catch (err) {}
  },
);

export const createFlashEvents = createAsyncThunk(
  "flashEvents/createFlashEvents",
  async (body: BodyCUFlashEvent) => {
    try {
      const param = paramsValue(body);
      const response = await client.post(`${Endpoint.FLASH_EVENTS}`, param);
      return response;
    } catch (err) {}
  },
);

export const deleteFlashEvents = createAsyncThunk(
  "flashEvents/deleteFlashEvents",
  async (flashEventId: string) => {
    try {
      const response = await client.delete(
        `${Endpoint.FLASH_EVENTS}/${flashEventId}`,
      );
      return response;
    } catch (err) {}
  },
);
const removeId = ({ id, ...rest }: BodyCUFlashEvent) => rest;
export const paramsValue = (body: BodyCUFlashEvent) => {
  return {
    eventType: Number(body.eventType),
    displayTime: new Date(body.displayTime),
    endTime: new Date(body.endTime),
    status: Number(body.status),
    banner: body.banner,
    detailId: body.detailId,
  };
};
