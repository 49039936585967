/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current } from "@reduxjs/toolkit";
import { getPools } from "./actions";
import { PublicKey } from "@solana/web3.js";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { Column, DataStatus } from "constant/interface";

export interface TokenPool {
  id?: string;
  duration: number;
  maxPool: number;
  stakeHolder?: number;
  minStake: number;
  maxStake: number;
  totalStaked?: number;
  yourStaked?: number;
  accruedReward?: number;
  payoutReward?: number;
  rewardAvailable?: number;
  rewardAmount?: number;
  apr?: number;
  endIn?: number;
  startIn: number;
  startStakeIn: number;
  createdBy?: string;
  wallet?: WalletContextState;
  publicKey?: PublicKey;
}

export type TokenPoolReceiveRaceFi = TokenPool & {
  apr: number;
};

export type TokenPoolReceiveSOL = TokenPool & {
  rewardAmount: number;
};

export type StakingPool = {
  apr?: number;
  rewardAmount?: number;
} & TokenPool;

export interface Ticket {
  id: string;
  user: string;
  pool: string;
  tnx: string;
  slot: number;
  createdAt: number;
}
export interface Staker {
  id: string;
  user: string;
  pool: string;
  tnx: string;
  slot: number;
  createdAt: number;
  time: number;
  date: string;
  amount: string;
  action: string;
}

export interface CreatePoolParam {
  minStake: number;
  maxStake: number;
  maxPool: number;
  duration: number;
  apr?: number;
  rewardAmount?: number;
  stakeHolder?: number;
  startIn: number;
  startStakeIn: number;
  endIn: number;
  wallet?: WalletContextState;
  publicKey?: PublicKey;
}
export interface ParamUDPool {
  wallet: WalletContextState;
  publicKey: any;
  poolSelected: TokenPool;
}
export enum PoolStatus {
  UNKNOWN,
  BEFORE_START_TIME_STAKE,
  STAKE_ALLOWED,
  STAKE_HAPPENING,
  STAKE_ENDED,
}

export enum TopStakerType {
  RACEFI = "v2",
  SOL = "sol",
}
export enum StakingPoolType {
  RACEFI = "racefi",
  SOL = "sol",
}

export interface StakingState {
  pools: StakingPool[];
  poolsStatus: DataStatus;
  poolStatus: PoolStatus;
  pool?: TokenPoolReceiveRaceFi | TokenPoolReceiveSOL;
  tickets: Ticket[];
  ticketStatus: DataStatus;
  dataTabHeaderRaceFi: Column[];
  dataTabHeaderSol: Column[];

  topStakersStatus: DataStatus;
  topStakers: Staker[];
  formConfigPoolRaceFi: unknown[];
  formConfigPoolSol: unknown[];
  formConfigCreatePool: unknown[];
  formConfigUpdatePool: unknown[];
}

const initialState: StakingState = {
  pools: [],
  poolsStatus: DataStatus.Idle,
  poolStatus: PoolStatus.UNKNOWN,
  tickets: [],
  ticketStatus: DataStatus.Idle,
  topStakersStatus: DataStatus.Idle,
  topStakers: [],
  dataTabHeaderRaceFi: [
    {
      id: "startIn",
      label: "Start In",
      minWidth: "17%",
    },
    {
      id: "maxPool",
      label: "Max Pool",
      minWidth: "17%",
    },
    {
      id: "duration",
      label: "Duration (d)",
      minWidth: "17%",
    },
    {
      id: "apr",
      label: "Apr (%)",
      minWidth: "17%",
    },
    {
      id: "withdraw",
      label: "Withdraw reward",
      minWidth: "13%",
    },
    {
      id: "action",
      label: "Action",
      minWidth: "13%",
    },
  ],
  dataTabHeaderSol: [
    {
      id: "startIn",
      label: "Start In",
      minWidth: "17%",
    },
    {
      id: "maxPool",
      label: "Max Pool",
      minWidth: "17%",
    },
    {
      id: "duration",
      label: "Duration (d)",
      minWidth: "17%",
    },
    {
      id: "rewardAmount",
      label: "Reward Amount (Sol)",
      minWidth: "17%",
    },
    {
      id: "action",
      label: "Action",
      minWidth: "20%",
    },
  ],
  formConfigPoolRaceFi: [
    {
      type: "formMinMax",
      data: [
        {
          key: "minStake",
          label: "Min Stake",
          description: "The minimum locked amount",
          type: "number",
        },
        {
          key: "maxStake",
          label: "Max Stake",
          description: "The maximum locked amount",
          type: "number",
        },
      ],
    },
    {
      type: "formRaceFi",
      data: [
        {
          key: "maxPool",
          label: "Max Pool",
          description: "Max Pool",
          type: "number",
        },
        {
          key: "duration",
          label: "Duration (d)",
          description: "Max Stake",
          type: "number",
        },
        {
          key: "apr",
          label: "Apr (%)",
          description: "Apr",
          type: "number",
        },
        {
          key: "stakeHolder",
          label: "Stake holder",
          type: "number",
        },
        {
          key: "totalStaked",
          label: "Total Staked",
          type: "number",
        },
      ],
    },
    {
      type: "formPicktime",
      data: [
        {
          key: "startStakeIn",
          label: "Start time stake",
          type: "picktime",
        },
        {
          key: "startIn",
          label: "Start rewards token",
          type: "picktime",
        },
        {
          key: "endIn",
          label: "End time stake",
          type: "picktime",
        },
      ],
    },
  ],
  formConfigPoolSol: [
    {
      type: "formMinMax",
      data: [
        {
          key: "minStake",
          label: "Min Stake",
          description: "The minimum locked amount",
          type: "number",
        },
        {
          key: "maxStake",
          label: "Max Stake",
          description: "The maximum locked amount",
          type: "number",
        },
      ],
    },
    {
      type: "formRaceFi",
      data: [
        {
          key: "maxPool",
          label: "Max Pool",
          description: "Max Pool",
          type: "number",
        },
        {
          key: "duration",
          label: "Duration (d)",
          description: "Max Stake",
          type: "number",
        },
        {
          key: "rewardAmount",
          label: "Reward Amount (Sol)",
          description: "Reward Amount",
          type: "number",
        },
        {
          key: "stakeHolder",
          label: "Stake holder",
          type: "number",
        },
        {
          key: "totalStaked",
          label: "Total Staked",
          type: "number",
        },
      ],
    },
    {
      type: "formPicktime",
      data: [
        {
          key: "startStakeIn",
          label: "Start time stake",
          type: "picktime",
        },
        {
          key: "startIn",
          label: "Start rewards token",
          type: "picktime",
        },
        {
          key: "endIn",
          label: "End time stake",
          type: "picktime",
        },
      ],
    },
  ],
  formConfigCreatePool: [
    {
      type: "formMinMax",
      data: [
        {
          key: "minStake",
          label: "Min Stake",
          type: "number",
        },
        {
          key: "maxStake",
          label: "Max Stake",
          type: "number",
        },
      ],
    },
    {
      type: "formP",
      data: [
        {
          key: "maxPool",
          label: "Max Pool",
          type: "number",
        },
        {
          key: "duration",
          label: "Duration (d)",
          type: "number",
        },
      ],
    },
    {
      type: "formPicktime",
      data: [
        {
          key: "startStakeIn",
          label: "Start time stake",
        },
        {
          key: "startIn",
          label: "Start rewards token",
        },
      ],
    },
  ],
  formConfigUpdatePool: [
    {
      type: "formMinMax",
      data: [
        {
          key: "minStake",
          label: "Min Stake",
          type: "number",
        },
        {
          key: "maxStake",
          label: "Max Stake",
          type: "number",
        },
      ],
    },
    {
      type: "formPicktime",
      data: [
        {
          key: "startIn",
          label: "Start rewards token",
        },
      ],
    },
  ],
};

const stakingSlice = createSlice({
  name: "staking",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
    updatePoolData(state, action) {
      if (action.payload?.pools) {
        state.pools = action.payload.pools;
      }
      if (action.payload?.poolsStatus) {
        state.poolsStatus = action.payload.poolsStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPools.pending, (state) => {
        state.poolsStatus = DataStatus.Loading;
      })
      .addCase(
        getPools.fulfilled,
        (state, { payload }: { payload: StakingPool[] }) => {
          state.poolsStatus = DataStatus.Succeeded;
          state.pools = payload;
        },
      )
      .addCase(getPools.rejected, (state) => {
        state.poolsStatus = DataStatus.Failed;
        state.pools = [];
      });
  },
});

export const { fieldChange, updatePoolData } = stakingSlice.actions;

export default stakingSlice.reducer;
