import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, Endpoint } from "api";
import { ParamGetTournament, ParamTournament } from "./reducer";

export const getTournament = createAsyncThunk(
  "tournament/getTournament",
  async (params: ParamGetTournament) => {
    try {
      const response = await client.get(Endpoint.TOURNAMENT, {
        params: params,
      });
      return response?.data.items;
    } catch (err) {}
  },
);

export const updateTournament = createAsyncThunk(
  "tournament/updateMetadata",
  async (body: ParamTournament) => {
    try {
      const param = paramsValue(body);
      const response = await client.put(
        `${Endpoint.TOURNAMENT}/${body.id}`,
        param,
      );
      return response;
    } catch (err) {}
  },
);

export const createTournament = createAsyncThunk(
  "tournament/createTournament",
  async (body: ParamTournament) => {
    try {
      const param = paramsValue(body);
      const response = await client.post(`${Endpoint.TOURNAMENT}`, param);
      return response;
    } catch (err) {}
  },
);

export const deleteTournament = createAsyncThunk(
  "tournament/deleteTournament",
  async (tournamentId: string) => {
    try {
      const response = await client.delete(
        `${Endpoint.TOURNAMENT}/${tournamentId}`,
      );
      return response;
    } catch (err) {}
  },
);
const removeId = ({ id, ...rest }: ParamTournament) => rest;
export const paramsValue = (body: ParamTournament) => {
  return {
    name: body.name,
    description: body.description,
    totalPrize: Number(body.totalPrize),
    racetrack: body.racetrack,
    class: body.class,
    laps: Number(body.laps),
    endRace: body.endRace,
    structurePrize: body.structurePrize,
    startTimeReg: body.startTimeReg,
    endTimeReg: body.endTimeReg,
    startRace: body.startRace,
    term: body.term,
    background: body.background,
    status: Number(body.status),
  };
};
