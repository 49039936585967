import { createSlice, current } from "@reduxjs/toolkit";
import { Column, Form } from "constant/interface";
import {
  postRacetrack,
  getRacetrack,
  getCollectionModel,
  deleteRacetrack,
} from "./actions";

export interface BodyUrlRacetrack {
  _id?: string;
  name: string;
  description: string;
  image: string;
  background: string;
  timeReq: number;
  laps: number;
  models: string[];
}
export interface Param {
  pageIndex: number;
  pageSize: number;
  search?: string;
}

export interface bodyCURacetrack {
  name: string;
  description: string;
  image: string;
  background: string;
  timeReq: number;
  laps: number;
  models: string[];
}
export interface ModelsCollection {
  id: string;
  name: string;
  description?: string;
  model?: string;
}
export interface RacetractState {
  racetrackItems: BodyUrlRacetrack[];
  dataRacetrack?: BodyUrlRacetrack;
  dataTabHeader: Column[];
  formConfig: Form[];
  models?: ModelsCollection[];
  params?: Param;
}

const initialState: RacetractState = {
  racetrackItems: [],
  params: {
    pageIndex: 1,
    pageSize: 100,
    search: "",
  },
  dataTabHeader: [
    {
      id: "name",
      label: "Name",
      minWidth: "20%",
    },
    {
      id: "laps",
      label: "Laps",
      minWidth: "15%",
    },
    {
      id: "timeReq",
      label: "TimeReq (s)",
      minWidth: "15%",
    },
    {
      id: "image",
      label: "Image",
      minWidth: "20%",
    },
    {
      id: "background",
      label: "Background",
      minWidth: "20%",
    },
    {
      id: "action",
      label: "Action",
      minWidth: "20%",
    },
  ],
  formConfig: [
    {
      key: "name",
      label: "Name",
      type: "text",
    },
    {
      key: "description",
      label: "Description",
      type: "text",
    },
    {
      key: "laps",
      label: "Laps",
      type: "number",
    },
    {
      key: "timeReq",
      label: "TimeReq",
      type: "number",
    },
    {
      key: "models",
      label: "Models",
      type: "dropdown",
    },
    {
      key: "image",
      label: "Image",
      type: "file",
    },
    {
      key: "background",
      label: "Background",
      type: "file",
    },
  ],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRacetrack.fulfilled, (state, action) => {
        state.racetrackItems = action.payload;
      })
      .addCase(postRacetrack.fulfilled, (state, action) => {
        if (action.payload?._id) {
          // state.racetrackItems.pop()
          state.racetrackItems.unshift(action.payload);
        }
      })
      .addCase(getCollectionModel.fulfilled, (state, action) => {
        state.models = action.payload;
      })
      .addCase(deleteRacetrack.fulfilled, (state, action) => {
        if (action.payload?._id) {
          state.racetrackItems = state.racetrackItems.filter(
            (item) => item._id !== action.payload?._id,
          );
        }
      });
  },
});

export const { fieldChange } = appSlice.actions;

export default appSlice.reducer;
