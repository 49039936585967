const typography = {
  fontFamily: "Coda",
  h1: {
    lineHeight: 1.5,
  },
  h2: {
    lineHeight: 1.5,
  },
  h3: {
    lineHeight: 1.5,
  },
  h4: {
    lineHeight: 1.5,
  },
  h5: {
    lineHeight: 1.5,
  },
  h6: {
    lineHeight: 1.5,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  overline: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.5,
  },
};

export default typography;
