/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Wallet } from "@project-serum/anchor";
import { useWallet } from "@solana/wallet-adapter-react";
import { PickTime, TextInputLayout } from "components";
import TemporaryDrawer from "components/Drawer";
import React, { memo, ReactNode, useState } from "react";
import { updateSnackbar } from "store/app";
import { useAuth } from "store/app/hooks";
import { ConfigMarketItem, getConfigMarket } from "store/configMarket";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { formatTimestamp2LocalDate } from "utils";
import { onConfigFee, ParamConfig } from "utils/configMarket";
export interface Column {
  id?: string;
  label?: string;
  minWidth?: number | string;
  align?: "right" | "center" | "left";
  type?: string;
}

export interface ITableLayoutProps {
  dataTabHead: Column[];
  dataTabRow: any[];
  onSelected?: (value) => void;
  onConfigCpn?: ReactNode;
}

const TableLayout = (props: ITableLayoutProps) => {
  const { dataTabHead, dataTabRow, onSelected } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { publicKey } = useAuth();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        border: "1px solid #343434",
        borderRadius: 2,
        backgroundColor: "transparent",
      }}
    >
      <TableContainer sx={{}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ width: "100%" }}>
              {dataTabHead.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ width: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTabRow
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                const itemConfig = item?.account ?? {};
                const row = item;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    sx={{}}
                  >
                    {dataTabHead.map((column) => {
                      const value = row[column?.id ?? ""];
                      const valueConfig = itemConfig[column?.id ?? ""] ?? "";
                      if (column?.id === "startIn") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {formatTimestamp2LocalDate(value)}
                          </TableCell>
                        );
                      } else if (column?.id === "detail") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <TemporaryDrawer
                              anchorDirection={"right"}
                              title="Detail"
                              onHandleClick={() => {
                                // dispatch(
                                //     fieldChange({
                                //         key: "dataRacetrack",
                                //         value: row,
                                //     }),
                                // );
                              }}
                            >
                              <Stack px={2}></Stack>
                            </TemporaryDrawer>
                          </TableCell>
                        );
                      } else if (
                        column?.id === "delete" &&
                        row["createdBy"] === publicKey?.toBase58() &&
                        row["stakeHolder"] === 0
                      ) {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="outlined"
                              color="error"
                              startIcon={<DeleteIcon />}
                              onClick={() => onSelected && onSelected(row)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={dataTabRow?.length ?? 1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default memo(TableLayout);

const initialPicktime = {
  startTimeStake: new Date().setMinutes(new Date().getMinutes() + 30),
  startValueDate: new Date().setMinutes(new Date().getMinutes() + 60),
};
