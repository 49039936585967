/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, memo, useMemo } from "react";

import "react-image-crop/dist/ReactCrop.css";
import { Box, Button, Stack } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { updateSnackbar } from "store/app";
import CropImage from "./CropImage";

type CoverProps = {
  onChangeFile: (data: any) => void;
  selectedValue?: string;
};

const ResizeImageLayout = (props: CoverProps) => {
  const { onChangeFile, selectedValue } = props;
  const dispatch = useAppDispatch();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files || !files.length) return;
    if (["image/png", "image/jpeg"].includes(files[0].type)) {
      setFile(files[0]);
    } else {
      dispatch(
        updateSnackbar({ message: "File is invalid.", severity: "error" }),
      );
    }
  };

  const onClose = () => {
    if (!inputFileRef?.current) return;
    inputFileRef.current.value = "";
    setFile(null);
  };

  const onResized = (data: File) => {
    onChangeFile && onChangeFile(data);
  };

  return (
    <Stack className="App" sx={{ flex: 1 }} width="100%">
      <Button variant="outlined" component="label" sx={{ my: 1 }}>
        Upload
        <Box
          ref={inputFileRef}
          accept="image/png, image/jpeg"
          onChange={onChange}
          component="input"
          type="file"
          hidden
        />
      </Button>
      {Boolean(file) && (
        <CropImage
          onClose={onClose}
          aspect={2}
          file={file as File}
          onResized={onResized}
        />
      )}
    </Stack>
  );
};
export default memo(ResizeImageLayout);
