import { BN } from "@project-serum/anchor";
import { createSlice, current } from "@reduxjs/toolkit";
import { getConfigMarket } from "./actions";
import { PublicKey } from "@solana/web3.js";
import { Column } from "components/Table";

export interface AccountItem {
  authorize: PublicKey;
  beneficiary: PublicKey;
  fee: BN;
  newAuthorize?: PublicKey;
}

export interface ConfigMarketItem {
  account: AccountItem;
  publicKey: PublicKey;
}

export interface ConfigMarketState {
  configMarketItems: ConfigMarketItem[];
  dataTabHeader: Column[];
  formConfig: { key: string; label: string; type?: string }[];
}

const initialState: ConfigMarketState = {
  configMarketItems: [],
  dataTabHeader: [
    {
      id: "authorize",
      label: "Old authorize",
      minWidth: "25%",
      type: "publickey",
    },
    {
      id: "beneficiary",
      label: "Beneficiary",
      minWidth: "25%",
      type: "publickey",
    },
    {
      id: "fee",
      label: "Fee (%)",
      minWidth: "25%",
      type: "BN",
    },
    {
      id: "config",
      label: "Config",
      minWidth: "25%",
      type: "button",
    },
  ],
  formConfig: [
    {
      key: "authorize",
      label: "Old Authorize",
      type: "publickey",
    },
    {
      key: "newAuthorize",
      label: "New Authorize",
      type: "publickey",
    },
    {
      key: "fee",
      label: "Fee",
      type: "BN",
    },
    {
      key: "beneficiary",
      label: "New beneficiary",
      type: "BN",
    },
  ],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfigMarket.fulfilled, (state, action) => {
      if (action.payload) {
        state.configMarketItems = action.payload ?? [];
      }
    });
  },
});

export const { fieldChange } = appSlice.actions;

export default appSlice.reducer;
