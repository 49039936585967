export interface Form {
  key: string;
  label: string;
  type: string;
  selectMultiple?: boolean;
}

export interface Column {
  id?: string;
  label?: string;
  minWidth?: number | string;
  align?: "right" | "center" | "left";
  type?: string;
}

export enum DataStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}
