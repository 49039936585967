export const Endpoint = {
  AUTH: "/live-ops/auth",
  RACETRACK: "/live-ops/race-track",
  UPLOAD: "/live-ops/upload/presigned-url",
  COLLECTION_MODEL: "/live-ops/collection",
  METADATA: "/live-ops/metadata/default/all",
  UDMETADATA: "/live-ops/metadata",
  TOURNAMENT: "/live-ops/tournament",
  PACKAGESPONSOR: "/live-ops/package-sponsor",
  SPONSOR: "/live-ops/sponsor",
  FLASH_EVENTS: "/live-ops/flash-event",
  GAME_CONFIG: "live-ops/game-config",
};
