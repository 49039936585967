import { Wallet } from "@project-serum/anchor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyProgram } from "utils/configMarket";

export const getConfigMarket = createAsyncThunk(
  "config/getConfigMarket",
  async (wallet: Wallet) => {
    try {
      const walletConfigByProgram = await getMyProgram(
        wallet,
      ).account.marketplaceConfig.all();

      return walletConfigByProgram;
    } catch (err) {
      console.error(err);
    }
  },
);
