import React, { memo, useEffect, useState } from "react";
import { Stack, Checkbox, FormControlLabel } from "@mui/material";
import { TooltipsLayout } from "components";

type CheckboxlayoutProps = {
  label: string;
  onChange: (value) => void;
  valueSelect?: boolean;
  description?: string;
};

const Checkboxlayout = (props: CheckboxlayoutProps) => {
  const { label, valueSelect, onChange, description } = props;
  const [checkValue, setCheck] = useState(false);
  const handleChange = () => {
    setCheck(!checkValue);
  };

  useEffect(() => {
    if (!valueSelect) return;
    setCheck(valueSelect);
  }, []);

  useEffect(() => {
    if (!checkValue) return;
    if (onChange) onChange(checkValue);
  }, [checkValue]);
  return (
    <Stack>
      <TooltipsLayout title={description ? description : ''} >
        <FormControlLabel
          value={checkValue}
          control={<Checkbox />}
          label={label}
          labelPlacement="end"
          checked={checkValue}
          onChange={handleChange}
        />
      </TooltipsLayout>
    </Stack>
  );
};

export default memo(Checkboxlayout);
