/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
import { Column } from "components/Table";
import { TournamentItem } from "store/tournament";
import { onGetConfigNft, onGetConfigSpl } from "./actions";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";

export interface Account {
  authority: PublicKey;
  paused: boolean;
  signer: PublicKey;
}
export interface RepData {
  account: Account;
  publicKey: PublicKey;
}

export interface EconConfigState {
  dataConfigNft?: RepData;
  dataConfigSpl?: RepData;
}

export enum EconConfigType {
  NFT,
  SPL,
}

const initialState: EconConfigState = {};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onGetConfigNft.fulfilled, (state, action) => {
        if (action.payload) {
          state.dataConfigNft = action.payload;
        }
      })
      .addCase(onGetConfigSpl.fulfilled, (state, action) => {
        if (action.payload) {
          state.dataConfigSpl = action.payload;
        }
      });
  },
});

export const { fieldChange } = appSlice.actions;

export default appSlice.reducer;
