/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { TooltipsLayout } from "components";
import { memo, useEffect, useState } from "react";
import {
  formatData,
  formatInputNumber,
  formatNumber,
  formatNumbers,
} from "utils";

type TextFieldProps = {
  value?: number | string;
  placeHolder: string | undefined;
  name: string;
  type: string;
  align?: string;
  onChangeValue?: (e) => void;
  onFocus?: () => void;
  error?: string;
  style?: object;
  readonly?: boolean;
  defaultValue?: string;
  disabledInput?: boolean;
  description?: string;
  sx?: SxProps;
  isCustom?: boolean;
};

const TextField = (props: TextFieldProps) => {
  const {
    value = "",
    name,
    onChangeValue,
    type,
    error,
    onFocus,
    defaultValue,
    disabledInput,
    description,
    isCustom,
  } = props;

  const [textValue, setTextValue] = useState<string | number>(
    type === "number" ? 0 : "",
  );
  const onChange = (event) => {
    if (type === "number") {
      if (event.target.value < 0) {
        // setTextValue(0);
        onChangeValue && onChangeValue(0);
      } else {
        setTextValue(event.target.value);
        onChangeValue && onChangeValue(event.target.value);
      }
    } else {
      setTextValue(event.target.value);
      onChangeValue && onChangeValue(event.target.value);
    }
  };
  useEffect(() => {
    if (!textValue) {
      setTextValue(value);
    }
  }, []);
  const onBlur = () => {
    {
    }
  };

  function formatCurrency(input, blur?: any) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    let input_val = input.target.value;

    // don't validate empty input
    if (input_val === "") {
      return;
    }

    // original length
    const original_len = input_val.length;

    // // initial caret position
    // let caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(".") >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      const decimal_pos = input_val.indexOf(".");

      // split number by decimal point
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatNumber(left_side);

      // validate right side
      right_side = formatNumber(right_side);

      // On blur make sure 2 numbers after decimal
      if (blur === "blur") {
        right_side += "00";
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      input_val = left_side + "." + right_side;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatNumber(input_val);
      input_val = input_val;

      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    console.log(input_val, "---input_val---");

    setTextValue(input_val);
    return input_val;
    // send updated string to input
    // input.val(input_val);

    // put caret back in the right position
    // const updated_len = input_val.length;
    // caret_pos = updated_len - original_len + caret_pos;
    // input[0].setSelectionRange(caret_pos, caret_pos);
  }
  return (
    <Stack direction="column" position="relative" {...props}>
      <Stack direction="row" width="100%" sx={{}}>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          {!isCustom && (
            <>
              {description ? (
                <TooltipsLayout title={description}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    {name}
                  </InputLabel>
                </TooltipsLayout>
              ) : (
                <InputLabel htmlFor="outlined-adornment-password">
                  {name}
                </InputLabel>
              )}
            </>
          )}

          <OutlinedInput
            id="outlined-adornment-password"
            type={type}
            value={textValue}
            onChange={onChange}
            disabled={disabledInput ?? false}
            defaultValue={defaultValue}
            onFocus={onFocus}
            placeholder={name}
            label={!isCustom ? name : null}
            // onKeyDown={(event) => formatCurrency(event)}
            // onBlur={(event) => formatCurrency(event, 'blur')}
            // pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
          />
        </FormControl>
      </Stack>
      {Boolean(error) && (
        <Typography
          width={250}
          variant="body2"
          position="absolute"
          right={0}
          bottom={-23}
          textAlign="right"
          color="error.main"
        >
          {error}
        </Typography>
      )}
    </Stack>
  );
};

export default memo(TextField);
