import React, { memo } from "react";
import { Stack, FormGroup, FormControlLabel, styled } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Styled from "./index.module.scss";

type ToggleLayoutProps = {
  label: string;
  checkValue: boolean;
  onChangeChecked: () => void;
};

const ToggleLayout = (props: ToggleLayoutProps) => {
  const { label, checkValue = false, onChangeChecked } = props;
  return (
    <Stack className={Styled["wrapper-container"]}>
      <FormGroup>
        <FormControlLabel
          control={<SwitchIcon defaultChecked sx={{ ml: 5 }} />}
          label={label}
          onChange={onChangeChecked}
          labelPlacement="start"
          checked={checkValue}
          className={Styled["tgl tgl-skewed"]}
          id={Styled["cb3"]}
        />
      </FormGroup>
      {/* <input className={Styled["tgl-skewed"]} id={Styled["cb3"]} type="checkbox"/>
        <label className={Styled["tgl-btn"]} data-tg-off="OFF" data-tg-on="ON" htmlFor="cb3"></label> */}
    </Stack>
  );
};

export default memo(ToggleLayout);

const SwitchIcon = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
