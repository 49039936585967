import * as anchor from "@project-serum/anchor";
import { BN, Wallet } from "@project-serum/anchor";
import * as SPLToken from "@solana/spl-token";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import { IDL as StakeV2Idl, StakeV2 } from "assets/programs/stake_v2";
import {
  IDL as StakingSolIdl,
  StakingSol,
} from "assets/programs/staking_receive_sol_program";
import {
  RACEFI_TOKEN,
  SOLANA_ENDPOINT,
  STAKE_V2_CONTRACT,
  STAKING_RECEIVE_SOL_CONTRACT,
  TIMESTAMP_DAY,
} from "constant";
import {
  ParamUDPool,
  TokenPool,
  TokenPoolReceiveRaceFi,
  TokenPoolReceiveSOL,
} from "store/staking";
import { decimalToNumber } from "utils";

const TOKEN_DECIMALS = 6;
const LAMPORTS_PER_TOKEN = Math.pow(10, TOKEN_DECIMALS);

type PoolInfo = {
  amount: BN;
  claimed: BN;
  time: BN;
  pool: PublicKey;
  user: PublicKey;
};

export const getMyProgram = (
  myWallet: Wallet,
  contractId = STAKE_V2_CONTRACT,
) => {
  const programId = new anchor.web3.PublicKey(contractId);
  const connection = new Connection(SOLANA_ENDPOINT, "confirmed");
  const provider = new anchor.AnchorProvider(connection, myWallet, {
    preflightCommitment: "recent",
  });

  const isStakingReceiveRaceFi = contractId === STAKE_V2_CONTRACT;

  if (isStakingReceiveRaceFi) {
    return new anchor.Program<StakeV2>(StakeV2Idl, programId, provider);
  }

  return new anchor.Program<StakingSol>(StakingSolIdl, programId, provider);
};

const getSPLTokenMint = (program, addressTokenMint, myWallet) => {
  const splTokenMint = new SPLToken.Token(
    program.provider.connection,
    addressTokenMint,
    SPLToken.TOKEN_PROGRAM_ID,
    myWallet,
  );
  return splTokenMint;
};

const getYourInfoByPool = async (
  program,
  publicKey: PublicKey,
  poolPublicKey: PublicKey,
) => {
  try {
    const [stakeInfoAccount] = await PublicKey.findProgramAddress(
      [poolPublicKey.toBuffer(), publicKey.toBuffer()],
      program.programId,
    );
    return await program.account.stakeInfo.fetch(stakeInfoAccount);
  } catch (error) {
    return {};
  }
};

export const getPoolInfo = async (
  program,
  poolPublicKey: PublicKey,
  publicKey?: PublicKey | null,
) => {
  try {
    const stakePool = await program.account.stakePool.fetch(poolPublicKey);

    const infoAccount = publicKey
      ? await getYourInfoByPool(program, publicKey, poolPublicKey)
      : {};
    return {
      ...stakePool,
      ...infoAccount,
    };
  } catch (error) {
    return null;
  }
};

export const getAccruedReward = (
  duration: number,
  apr: number,
  yourStaked?: number,
) => {
  if (!yourStaked) return undefined;
  return (yourStaked * duration * apr) / 365;
};

const getClaimableRewards = (estRewards: number) => {
  return estRewards > 1 / LAMPORTS_PER_TOKEN ? estRewards : 0;
};

export const getRewardByPool = (
  duration: number,
  startIn: number,
  accruedReward?: number,
  payoutReward?: number,
) => {
  if (!accruedReward || (!payoutReward && payoutReward !== 0)) return;

  if (startIn >= Date.now()) return 0; // Not start calc rewards

  const endIn = startIn + duration * TIMESTAMP_DAY;

  const rateBySec = accruedReward / duration / 24 / 60 / 60;

  const totalRewardAvailableFromStakeTime =
    (((Date.now() >= endIn ? endIn : Date.now()) - startIn) / 1000) * rateBySec;
  return totalRewardAvailableFromStakeTime > accruedReward
    ? 0
    : getClaimableRewards(totalRewardAvailableFromStakeTime - payoutReward);
};

export const getAccruedRewardReceiveSOL = (
  rewardAmount: number,
  totalStaked: number,
  yourStaked?: number,
) => {
  if (!yourStaked) return undefined;
  return (rewardAmount * yourStaked) / totalStaked;
};

export const getRaceFiRaceFiPools = async (wallet) => {
  try {
    const program = getMyProgram(
      wallet,
      STAKE_V2_CONTRACT,
    ) as anchor.Program<StakeV2>;

    const pools = await program.account.stakePool.all();

    const poolList = pools.map((pool) => {
      const startIn = pool.account.startValueDate.toNumber() * 1000;
      const duration = pool.account.duration.toNumber();

      return {
        id: pool.publicKey.toBase58(),
        apr: pool.account.apr.toNumber() / LAMPORTS_PER_SOL,
        duration,
        maxPool: decimalToNumber(pool.account.maxPool.toNumber()),
        minStake: decimalToNumber(pool.account.minStake.toNumber()),
        stakeHolder: pool.account.stakeholder.toNumber(),
        maxStake: decimalToNumber(pool.account.maxStake.toNumber()),
        totalStaked: decimalToNumber(pool.account.totalStaked.toNumber()),
        startIn,
        endIn: startIn + duration * TIMESTAMP_DAY,
        startStakeIn: pool.account.startTimeStake.toNumber() * 1000,
        createdBy: pool.account.authorize.toBase58(),
        publicKey: pool.publicKey,
      };
    }) as TokenPoolReceiveRaceFi[];
    return poolList;
  } catch (error) {
    throw error;
  }
};
export const getRaceFiSolanaPools = async (wallet) => {
  try {
    const program = getMyProgram(
      wallet,
      STAKING_RECEIVE_SOL_CONTRACT,
    ) as anchor.Program<StakingSol>;
    const pools = await program.account.stakePool.all();

    const poolList = pools.map((pool) => {
      const startIn = pool.account.startValueDate.toNumber() * 1000;
      const duration = pool.account.duration.toNumber();
      return {
        id: pool.publicKey.toBase58(),
        duration,
        rewardAmount: pool.account.rewardAmount.toNumber() / LAMPORTS_PER_SOL,
        maxPool: decimalToNumber(pool.account.maxPool.toNumber()),
        minStake: decimalToNumber(pool.account.minStake.toNumber()),
        stakeHolder: pool.account.stakeholder.toNumber(),
        maxStake: decimalToNumber(pool.account.maxStake.toNumber()),
        totalStaked: decimalToNumber(pool.account.totalStaked.toNumber()),
        startIn,
        endIn: startIn + duration * TIMESTAMP_DAY,
        startStakeIn: pool.account.startTimeStake.toNumber() * 1000,
        createdBy: pool.account.authorize.toBase58(),
      };
    }) as TokenPoolReceiveSOL[];

    return poolList;
  } catch (error) {
    throw error;
  }
};

const splTokenMint = (program, addressTokenMint, myWallet) => {
  const splTokenMint = new SPLToken.Token(
    program.provider.connection,
    addressTokenMint,
    SPLToken.TOKEN_PROGRAM_ID,
    myWallet,
  );
  return splTokenMint;
};
// Math.round(Date.now() / 1000 + 16 * 3600)
export const onCreatePoolV2 = async (poolParam: TokenPool) => {
  if (!poolParam.wallet || !poolParam.publicKey) return;

  const pool = Keypair.generate();
  const program = getMyProgram(
    poolParam.wallet as unknown as Wallet,
    STAKE_V2_CONTRACT,
  ) as anchor.Program<StakeV2>;

  try {
    const [escrowedTokenAccount, escrowedTokenBump] =
      await PublicKey.findProgramAddress(
        [pool.publicKey.toBuffer()],
        program.programId,
      );

    const minStake = new BN(poolParam.minStake * Math.pow(10, TOKEN_DECIMALS));
    const maxStake = new BN(poolParam.maxStake * Math.pow(10, TOKEN_DECIMALS));
    const maxPool = new BN(poolParam.maxPool * Math.pow(10, TOKEN_DECIMALS));
    const duration = new BN(poolParam.duration);
    const apr = new BN(((poolParam.apr ?? 1) * LAMPORTS_PER_SOL) / 100);
    const mintToken = splTokenMint(
      program,
      new PublicKey(RACEFI_TOKEN),
      poolParam.wallet,
    );

    const creatorATA = await mintToken.getOrCreateAssociatedAccountInfo(
      poolParam.publicKey,
    );

    await program.methods
      .createStakePool(
        escrowedTokenBump,
        minStake,
        maxStake,
        maxPool,
        duration,
        apr,
        new BN(Math.round(poolParam.startStakeIn / 1000)),
        new BN(Math.round(poolParam.startIn / 1000)),
      )
      .accounts({
        pool: pool.publicKey,
        creator: poolParam.publicKey,
        creatorTokenAccount: creatorATA.address,
        escrowedTokenAccount: escrowedTokenAccount,
        stakeToken: new PublicKey(RACEFI_TOKEN),
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      })
      .signers([pool])
      .rpc();

    const stakePool = await program.account.stakePool.fetch(pool.publicKey);
    return stakePool;
  } catch (error) {
    console.error(error);
  }
};

export const onCreatePoolSol = async (poolParam: TokenPool) => {
  if (!poolParam.wallet || !poolParam.publicKey) return;

  const pool = Keypair.generate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const program = getMyProgram(
    poolParam.wallet as unknown as Wallet,
    STAKING_RECEIVE_SOL_CONTRACT,
  ) as anchor.Program<StakingSol>;

  try {
    const [escrowedTokenAccount, escrowedTokenBump] =
      await PublicKey.findProgramAddress(
        [pool.publicKey.toBuffer(), new PublicKey(RACEFI_TOKEN).toBuffer()],
        program.programId,
      );

    const [escrowedRewardAccount, escrowedRewardBump] =
      await PublicKey.findProgramAddress(
        [pool.publicKey.toBuffer()],
        program.programId,
      );

    const minStake = new BN(poolParam.minStake * LAMPORTS_PER_TOKEN);
    const maxStake = new BN(poolParam.maxStake * LAMPORTS_PER_TOKEN);
    const maxPool = new BN(poolParam.maxPool * LAMPORTS_PER_TOKEN);
    const rewardAmount = new BN(
      Number(poolParam.rewardAmount) * LAMPORTS_PER_SOL,
    );
    const duration = new BN(Number(poolParam.duration));
    const startValueDate = new BN(Math.round(poolParam.startIn / 1000));
    const startTimeStake = new BN(Math.round(poolParam.startStakeIn / 1000));

    await program.methods
      .createStakePool(
        escrowedTokenBump,
        escrowedRewardBump,
        minStake,
        maxStake,
        maxPool,
        rewardAmount,
        duration,
        startTimeStake,
        startValueDate,
      )
      .accounts({
        pool: pool.publicKey,
        creator: poolParam.publicKey,
        escrowedTokenAccount: escrowedTokenAccount,
        escrowedRewardAccount: escrowedRewardAccount,
        stakeToken: new PublicKey(RACEFI_TOKEN),
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      })
      .signers([pool])
      .rpc();

    const stakePool = await program.account.stakePool.fetch(pool.publicKey);
    return stakePool;
  } catch (error) {
    console.error(error);
  }
};
export const onClose = async (paramClosePool: ParamUDPool) => {
  if (!paramClosePool.wallet || !paramClosePool.publicKey) return;
  const pool = paramClosePool.poolSelected; // Fixed index 0
  try {
    const program = getMyProgram(paramClosePool.wallet as unknown as Wallet);

    const [escrowedTokenAccount, escrowedTokenBump] =
      await PublicKey.findProgramAddress(
        [(pool.publicKey as PublicKey).toBuffer()],
        program.programId,
      );

    const splTokenBuy = splTokenMint(
      program,
      new PublicKey(RACEFI_TOKEN),
      paramClosePool.wallet,
    );

    const creatorATA = await splTokenBuy.getOrCreateAssociatedAccountInfo(
      paramClosePool.publicKey,
    );

    const data = await program.methods
      .closePool()
      .accounts({
        pool: pool.publicKey,
        authorize: paramClosePool.publicKey,
        authorizeTokenAccount: creatorATA.address,
        escrowedTokenAccount: escrowedTokenAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .rpc();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const onWithdrawRewardPool = async (
  paramWithdrawReward: ParamUDPool,
) => {
  if (!paramWithdrawReward.wallet || !paramWithdrawReward.publicKey) return;
  const pool = paramWithdrawReward.poolSelected; // Fixed index 0
  try {
    const program = getMyProgram(
      paramWithdrawReward.wallet as unknown as Wallet,
    ) as anchor.Program<StakeV2>;

    const [escrowedTokenAccount, escrowedTokenBump] =
      await PublicKey.findProgramAddress(
        [(pool.publicKey as PublicKey).toBuffer()],
        program.programId,
      );

    const splTokenBuy = splTokenMint(
      program,
      new PublicKey(RACEFI_TOKEN),
      paramWithdrawReward.wallet,
    );

    const creatorATA = await splTokenBuy.getOrCreateAssociatedAccountInfo(
      paramWithdrawReward.publicKey,
    );

    const data = await program.methods
      .withdrawReward()
      .accounts({
        pool: pool.publicKey,
        authorize: paramWithdrawReward.publicKey,
        authorizeTokenAccount: creatorATA.address,
        escrowedTokenAccount: escrowedTokenAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .rpc();
    console.log("🚀 data", data);

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const onUpdatePool = async (paramUpdatePool: ParamUDPool) => {
  if (!paramUpdatePool.wallet || !paramUpdatePool.publicKey) return;

  const pool = paramUpdatePool.poolSelected; // Fixed index 0
  const program = getMyProgram(
    paramUpdatePool.wallet as unknown as Wallet,
    STAKE_V2_CONTRACT,
  ) as anchor.Program<StakeV2>;

  try {
    const minStake = new BN(pool.minStake * Math.pow(10, TOKEN_DECIMALS));
    const maxStake = new BN(pool.maxStake * Math.pow(10, TOKEN_DECIMALS));
    await program.methods
      .updatePool(minStake, maxStake, new BN(Math.round(pool.startIn / 1000)))
      .accounts({
        pool: pool.publicKey,
        authorize: paramUpdatePool.publicKey,
      })
      .rpc();

    const stakePool = await program.account.stakePool.fetch(
      paramUpdatePool.publicKey,
    );
    return stakePool;
  } catch (error) {
    console.error(error);
  }
};
export const onClosePoolSol = async (paramClosePool: ParamUDPool) => {
  // debugger
  if (!paramClosePool.wallet || !paramClosePool.publicKey) return;
  const pool = new PublicKey(paramClosePool.poolSelected.id as string); // Fixed index 0

  try {
    const program = getMyProgram(
      paramClosePool.wallet as unknown as Wallet,
      STAKING_RECEIVE_SOL_CONTRACT,
    ) as anchor.Program<StakingSol>;

    const [escrowedTokenAccount, escrowedTokenBump] =
      await PublicKey.findProgramAddress(
        [pool.toBuffer(), new PublicKey(RACEFI_TOKEN).toBuffer()],
        program.programId,
      );

    const [escrowedRewardAccount, escrowedRewardBump] =
      await PublicKey.findProgramAddress([pool.toBuffer()], program.programId);

    const splTokenBuy = splTokenMint(
      program,
      new PublicKey(RACEFI_TOKEN),
      paramClosePool.wallet,
    );

    const creatorATA = await splTokenBuy.getOrCreateAssociatedAccountInfo(
      paramClosePool.wallet.publicKey as PublicKey,
    );

    const data = await program.methods
      .closePool()
      .accounts({
        pool: pool,
        authorize: paramClosePool.wallet.publicKey as PublicKey,
        authorizeTokenAccount: creatorATA.address,
        escrowedTokenAccount: escrowedTokenAccount,
        escrowedRewardAccount: escrowedRewardAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .rpc();
    return data;
  } catch (error) {
    console.error(error);
  }
};
// const onClosePoolById = async ( {}) => {
//   if (!wallet || !publicKey) return;
//   const pool = pools[pIndex]; // Fixed index 0
//   try {
//     const program = getMyProgram(wallet as unknown as Wallet);

//     const [escrowedTokenAccount, escrowedTokenBump] =
//       await PublicKey.findProgramAddress(
//         [pool.publicKey.toBuffer()],
//         program.programId,
//       );

//     const splTokenBuy = splTokenMint(
//       program,
//       new PublicKey(AddressToken.RACEFI),
//       wallet,
//     );

//     const creatorATA = await splTokenBuy.getOrCreateAssociatedAccountInfo(
//       publicKey,
//     );

//     await program.methods
//       .closePool()
//       .accounts({
//         pool: pool.publicKey,
//         authorize: publicKey,
//         authorizeTokenAccount: creatorATA.address,
//         escrowedTokenAccount: escrowedTokenAccount,
//         tokenProgram: TOKEN_PROGRAM_ID,
//       })
//       .rpc();
//   } catch (error) {
//     console.error(error);
//   }
// };
