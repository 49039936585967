import base58 from "bs58";
import {
  DARK_THEME_MEDIA_SYSTEM,
  DEFAULT_LANGUAGE,
  NOT_HAVE_VALUE,
} from "constant";
import { AppearanceType } from "constant/enum";
import { LanguageType } from "constant/types";
import { clientStorage } from "utils/storage";

export const getTheme = (
  key: string,
  fallback: AppearanceType,
): AppearanceType => {
  if (typeof window === "undefined") return fallback;
  try {
    const theme =
      (clientStorage.get(key) as AppearanceType) || getThemeSystem();
    return theme || fallback;
  } catch (error) {
    // Unsupported
    console.error(error);
  }
  return fallback;
};

export const getThemeSystem = (e?: MediaQueryList): AppearanceType => {
  if (!e) {
    e = window.matchMedia(DARK_THEME_MEDIA_SYSTEM);
  }

  const isDark = e.matches;

  const themeSystem = isDark ? AppearanceType.Dark : AppearanceType.Light;
  return themeSystem;
};

export const getOSLanguage = () => {
  if (typeof window === "undefined") return DEFAULT_LANGUAGE as LanguageType;

  const language =
    window.navigator.language || Intl.DateTimeFormat().resolvedOptions().locale;

  return (language ? language.slice(0, 2) : DEFAULT_LANGUAGE) as LanguageType;
};

export const parseJSON = (data: string, defaultData: unknown): unknown => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error(error);
    return defaultData;
  }
};

export const getSignMessage = async (text: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sol = (window as any)?.solana;
  if (!sol) return null;
  try {
    const encodedMessage = new TextEncoder().encode(text);
    const signedMessage = await sol.signMessage(encodedMessage, "utf8");
    return base58.encode(signedMessage.signature) as string;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const shortText = (text?: string, fallback?: string) => {
  if (!text) return fallback ?? "--";
  return text.slice(0, 6) + "..." + text.slice(-4);
};

export const longText = (text?: string, toText?: number, fallback?: string) => {
  if (!text) return fallback ?? "--";
  return text.slice(0, toText ? toText : 10) + "..." + text.slice(-8);
};

export const decimalToNumber = (value: number) => value / Math.pow(10, 6);

export const formatTimestamp2LocalDate = (date: number, format?: string) => {
  if (!date) return "";
  if (!format) format = "yyyy-MM-dd hh:mm";
  const dateObj = new Date(date);

  const year = dateObj.getFullYear();

  if (year === 1 || year === 1970) return "";
  const day = `0${dateObj.getDate()}`.substr(-2);
  const month = `0${dateObj.getMonth() + 1}`.substr(-2);
  const hours = `0${dateObj.getHours()}`.substr(-2);
  const minutes = `0${dateObj.getMinutes()}`.substr(-2);
  const seconds = `0${dateObj.getSeconds()}`.substr(-2);
  let dateFormat = format.replace("yyyy", year.toString());
  dateFormat = dateFormat.replace("MM", month);
  dateFormat = dateFormat.replace("dd", day);
  dateFormat = dateFormat.replace("hh", hours);
  dateFormat = dateFormat.replace("mm", minutes);
  // dateFormat = dateFormat.replace("ss", seconds);

  return dateFormat;
};

export const formatNumber = (
  number?: number,
  numberOfFixed = 4,
  emptyText = NOT_HAVE_VALUE,
  localeOption = {},
  suffix?: string,
  space = true,
) => {
  const suffixParsed = suffix ? `${space ? " " : ""}${suffix}` : "";
  if (!number && number !== 0) return emptyText + suffixParsed;
  const num = Number(number || 0);
  const maximumFractionDigits = Number.isInteger(num) ? 0 : numberOfFixed;
  return (
    num.toLocaleString("en-US", {
      maximumFractionDigits,
      ...localeOption,
    }) + suffixParsed
  );
};

export const formatInputNumber = (number) => {
  const numFormat = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numFormat;
};
export const formatNumbers = (
  number?: number,
  numberOfFixed = 4,
  emptyText = NOT_HAVE_VALUE,
  localeOption = {},
  suffix?: string,
  space = true,
) => {
  const suffixParsed = suffix ? `${space ? " " : ""}${suffix}` : "";
  if (!number && number !== 0) return emptyText + suffixParsed;
  const num = Number(number || 0);
  const maximumFractionDigits = Number.isInteger(num) ? 0 : numberOfFixed;
  return (
    num.toLocaleString("en-US", {
      maximumFractionDigits,
      ...localeOption,
    }) + suffixParsed
  );
};

export const formatData = (data: string | number): string => {
  const convertValue = data
    .toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return convertValue;
};
