export type MarketplaceProgram = {
  version: "0.1.0";
  name: "marketplace_program";
  instructions: [
    {
      name: "initialize";
      accounts: [
        {
          name: "marketplaceConfig";
          isMut: true;
          isSigner: true;
        },
        {
          name: "authorize";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "beneficiary";
          type: "publicKey";
        },
        {
          name: "fee";
          type: "u64";
        },
      ];
    },
    {
      name: "updateConfig";
      accounts: [
        {
          name: "marketplaceConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "authorize";
          isMut: true;
          isSigner: true;
        },
      ];
      args: [
        {
          name: "newAuthorize";
          type: "publicKey";
        },
        {
          name: "newBeneficiary";
          type: "publicKey";
        },
        {
          name: "newFee";
          type: "u64";
        },
      ];
    },
    {
      name: "createExchangeItem";
      accounts: [
        {
          name: "offer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "marketplaceConfig";
          isMut: false;
          isSigner: false;
        },
        {
          name: "seller";
          isMut: true;
          isSigner: true;
        },
        {
          name: "sellerNftAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedNftAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "nft";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "escrowedNftBump";
          type: "u8";
        },
        {
          name: "payment";
          type: "publicKey";
        },
        {
          name: "amount";
          type: "u64";
        },
        {
          name: "price";
          type: "u64";
        },
      ];
    },
    {
      name: "createExchangeItemSaleWithSol";
      accounts: [
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "marketplaceConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedNftAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "buyerNftAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "buyer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "seller";
          isMut: true;
          isSigner: false;
        },
        {
          name: "beneficiary";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "createExchangeItemSaleWithToken";
      accounts: [
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "marketplaceConfig";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beneficiaryPaymentAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "sellerPaymentAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "buyerPaymentAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedNftAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "buyerNftAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "buyer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "seller";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "cancelExchangeItem";
      accounts: [
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "seller";
          isMut: true;
          isSigner: true;
        },
        {
          name: "sellerNftAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedNftAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
  ];
  accounts: [
    {
      name: "exchangeItem";
      type: {
        kind: "struct";
        fields: [
          {
            name: "seller";
            type: "publicKey";
          },
          {
            name: "nft";
            type: "publicKey";
          },
          {
            name: "payment";
            type: "publicKey";
          },
          {
            name: "price";
            type: "u64";
          },
          {
            name: "marketplaceConfig";
            type: "publicKey";
          },
          {
            name: "escrowedNftBump";
            type: "u8";
          },
        ];
      };
    },
    {
      name: "marketplaceConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authorize";
            type: "publicKey";
          },
          {
            name: "beneficiary";
            type: "publicKey";
          },
          {
            name: "fee";
            type: "u64";
          },
        ];
      };
    },
  ];
  errors: [
    {
      code: 6000;
      name: "InsufficientFundsForTransaction";
      msg: "InsufficientFundsForTransaction";
    },
  ];
};

export const IDL: MarketplaceProgram = {
  version: "0.1.0",
  name: "marketplace_program",
  instructions: [
    {
      name: "initialize",
      accounts: [
        {
          name: "marketplaceConfig",
          isMut: true,
          isSigner: true,
        },
        {
          name: "authorize",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "beneficiary",
          type: "publicKey",
        },
        {
          name: "fee",
          type: "u64",
        },
      ],
    },
    {
      name: "updateConfig",
      accounts: [
        {
          name: "marketplaceConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "authorize",
          isMut: true,
          isSigner: true,
        },
      ],
      args: [
        {
          name: "newAuthorize",
          type: "publicKey",
        },
        {
          name: "newBeneficiary",
          type: "publicKey",
        },
        {
          name: "newFee",
          type: "u64",
        },
      ],
    },
    {
      name: "createExchangeItem",
      accounts: [
        {
          name: "offer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "marketplaceConfig",
          isMut: false,
          isSigner: false,
        },
        {
          name: "seller",
          isMut: true,
          isSigner: true,
        },
        {
          name: "sellerNftAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedNftAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "nft",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "escrowedNftBump",
          type: "u8",
        },
        {
          name: "payment",
          type: "publicKey",
        },
        {
          name: "amount",
          type: "u64",
        },
        {
          name: "price",
          type: "u64",
        },
      ],
    },
    {
      name: "createExchangeItemSaleWithSol",
      accounts: [
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "marketplaceConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedNftAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "buyerNftAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "buyer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "seller",
          isMut: true,
          isSigner: false,
        },
        {
          name: "beneficiary",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "createExchangeItemSaleWithToken",
      accounts: [
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "marketplaceConfig",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beneficiaryPaymentAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "sellerPaymentAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "buyerPaymentAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedNftAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "buyerNftAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "buyer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "seller",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "cancelExchangeItem",
      accounts: [
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "seller",
          isMut: true,
          isSigner: true,
        },
        {
          name: "sellerNftAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedNftAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: "exchangeItem",
      type: {
        kind: "struct",
        fields: [
          {
            name: "seller",
            type: "publicKey",
          },
          {
            name: "nft",
            type: "publicKey",
          },
          {
            name: "payment",
            type: "publicKey",
          },
          {
            name: "price",
            type: "u64",
          },
          {
            name: "marketplaceConfig",
            type: "publicKey",
          },
          {
            name: "escrowedNftBump",
            type: "u8",
          },
        ],
      },
    },
    {
      name: "marketplaceConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authorize",
            type: "publicKey",
          },
          {
            name: "beneficiary",
            type: "publicKey",
          },
          {
            name: "fee",
            type: "u64",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "InsufficientFundsForTransaction",
      msg: "InsufficientFundsForTransaction",
    },
  ],
};
