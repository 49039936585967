import { Box, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Text } from "components/shared";
import { memo } from "react";
type PickTimeProps = {
  value?: number | Date;
  onChangeDate?: (date) => void;
  error?: string;
  lable?: string;
  minDateTime?: number;
};

const PickTime = (props: PickTimeProps) => {
  const { value = new Date(), onChangeDate, error, lable, minDateTime } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display="flex" flexDirection="column">
        <Stack>
          <Text mr={1} fontWeight={400} color="text.subtitle" sx={{ mb: 1 }}>
            {lable}:
          </Text>
          <DateTimePicker
            renderInput={(props) => (
              <TextField
                sx={{
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "& input": { color: "text.white" },
                  "& button": { color: "text.white" },
                }}
                {...props}
              />
            )}
            value={typeof value === "number" ? value : value.getTime()}
            onChange={(val) => onChangeDate && onChangeDate(new Date(val ?? 1))}
            ampm={false}
          />
        </Stack>
        {Boolean(error) && (
          <Typography textAlign="right" variant="body2" color="error.main">
            {error}
          </Typography>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default memo(PickTime);
