import React, { memo } from "react";
import {
  Stack,
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

type ModalLayoutProps = {
  handleDelete?: () => void;
};

const ModalLayout = (props: ModalLayoutProps) => {
  const { handleDelete } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteClick = () => {
    handleDelete && handleDelete();
    setOpen(false);
  };
  return (
    <Stack>
      <IconButton
        aria-label="delete"
        onClick={handleOpen}
        sx={{ width: "fit-content" }}
      >
        <DeleteOutlineOutlinedIcon color="error" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style}>
          <ErrorOutlineIcon sx={{ color: "error.main", fontSize: 100 }} />
          <Typography id="modal-modal-title" variant="h4" component="h2" my={2}>
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You will not be able to recover this imaginary file
          </Typography>
          <Stack
            direction="row"
            alignSelf="center"
            width="100%"
            justifyContent="space-around"
            my={2}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                width: 150,
                py: 1,
                alignSelf: "center",
                mt: 3,
                textTransform: "none",
                color: "white",
                backgroundColor: "gray",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteClick}
              variant="contained"
              color="error"
              sx={{
                width: 150,
                py: 1,
                alignSelf: "center",
                mt: 3,
                textTransform: "none",
                color: "white",
              }}
            >
              Yes, delete it!
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default memo(ModalLayout);

const style = {
  position: "absolute",
  top: "40%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "rgb(33, 38, 64)",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 4,
};
