import { Stack } from "@mui/material";
import { ReqConnectWallet, RoundterLinkLayout } from "components";
import Snackbar from "components/Snackbar";
import Header from "layouts/Header";
import Menu from "layouts/Menu";
import { useRouter } from "next/router";
import { memo } from "react";
import { useAuth } from "store/app/hooks";
import AppHead, { AppHeadProps } from "components/AppHead";

type MainLayoutProps = {
  children?: React.ReactNode;
  appHeadProps?: AppHeadProps;
};
// const MainLayout = (props: MainLayoutProps) => {
//   return (
//     <Menu>
//       <Stack width="100%" height="100%">
//         {props.children}
//       </Stack>
//     </Menu>
//   );
// };

const MainLayout = (props: MainLayoutProps) => {
  const { children, appHeadProps = {} } = props;
  const { publicKey } = useAuth();
  const { pathname } = useRouter();
  const isHomepage = Boolean(pathname == "/");
  return (
    <Stack direction="row" flex={1} boxSizing="border-box">
      <Menu sx={{ display: "initial", position: "fixed" }} />
      <Stack height="100vh" direction="column" flex={8}>
        <Header />
        <Stack id="body" sx={{ mt: 10, ml: "300px" }} position="relative">
          <RoundterLinkLayout />
          <Stack mt={5}>
            {!publicKey && !isHomepage ? (
              <ReqConnectWallet />
            ) : (
              <Stack px={5} flex={1}>
                {children}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <AppHead {...appHeadProps} />
      <Snackbar />
    </Stack>
  );
};

export default memo(MainLayout);
