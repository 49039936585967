import React, { memo } from 'react';
import { Stack, Paper, InputBase, IconButton, SxProps } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";

type SearchLayoutProps = {
    onHandleClick: (value: string) => void;
    sx?:SxProps;
}

const SearchLayout = (props: SearchLayoutProps) => {
    const [searchValue, setSearchValue] = React.useState("");

    const searchValueSelect = () => {
        if (props.onHandleClick) {
            props.onHandleClick(searchValue)
        }
    };

    return (
        <Stack direction="row" alignItems="center" {...props} >
            <Paper
                component="form"
                sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 600,
                }}
            >
                <InputBase
                    sx={{ pl: 1, flex: 1 }}
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <IconButton sx={{ p: "10px" }} onClick={searchValueSelect}>
                    <SearchIcon />
                </IconButton>
            </Paper>
        </Stack>
    )
}

export default memo(SearchLayout);
