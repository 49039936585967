import React, { Component, ReactNode } from 'react'
import { Stack, Box, Tabs, Tab, Typography, SxProps } from '@mui/material'

export interface Tabs {
    title: string;
    component?: ReactNode;
}

interface TabProps {
    dataValue: Tabs[];
    styled: SxProps;
    childrenCpn:ReactNode;
    onChangeIndex:(value:number) => void;
}
const TabPanels = (props: TabProps) => {
    const { dataValue, styled ,childrenCpn} = props;

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        props.onChangeIndex(newValue)
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}  >
                    {
                        dataValue.map((tab, i) => {
                            return (
                                <Tab key={i} label={tab.title} {...a11yProps(i)} sx={styled} />
                            )
                        }
                        )
                    }
                </Tabs>
            </Box>
            <TabPanel value={value} index={value} >
                {childrenCpn}
            </TabPanel>
            {/* {
                dataValue.map((item, index) => {
                    return (
                        <TabPanel key={index} value={value} index={index}>
                        </TabPanel>
                    )
                })
            } */}
        </Box>
    )
}
export default TabPanels;

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: 3, color: 'common.white' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}