import { createSlice } from "@reduxjs/toolkit";
import { Column } from "components/Table";
import { TournamentItem } from "store/tournament";
import {
  createPackageSponsor,
  createSponsor,
  getPackageSponsor,
  getSponsor,
} from "./actions";

// export enum FlashEventType {
//   Tournament = 1,
//   SaleNft,
// }

export enum Status {
  inactive = 1,
  active = 2,
}

export enum LogoSizeType {
  Small = 1,
  Medium,
  Big,
}

export interface PackageSponsorItem {
  name: string;
  speakingSlot: number;
  isMentionOnYoutube: boolean;
  isBannerLeaderboard: boolean;
  isLogoInTrack: boolean;
  isTwitterAnnouncement: boolean;
  isHighlightPost: boolean;
  logoSizeOnWeb: LogoSizeType;
  logoSizeOnOfficial: LogoSizeType;
  logoSizeOnMedia: LogoSizeType;
  isNewsletterEmail: boolean;
  isMentionedInServices: boolean;
  isCertificateOfSponsorship: boolean;
  isBecomeLongterm: boolean;
  createdAt: Date;
  updatedAt: Date;
  id: string;
}

export interface BodyCUPackageSponsor {
  name: string;
  speakingSlot: number;
  isMentionOnYoutube: boolean;
  isBannerLeaderboard: boolean;
  isLogoInTrack: boolean;
  isTwitterAnnouncement: boolean;
  isHighlightPost: boolean;
  logoSizeOnWeb: LogoSizeType;
  logoSizeOnOfficial: LogoSizeType;
  logoSizeOnMedia: LogoSizeType;
  isNewsletterEmail: boolean;
  isMentionedInServices: boolean;
  isCertificateOfSponsorship: boolean;
  isBecomeLongterm: boolean;
  id?: string;
}

export interface SponsorItem {
  name: string;
  description: string;
  logo: string;
  social: string;
  packageSponsor: PackageSponsorItem;
  tournament: TournamentItem[];
  createdAt: Date;
  updatedAt: Date;
  id: string;
}

export interface BodyCUSponsor {
  name: string;
  description: string;
  packageSponsorId: string;
  tournamentId: string[];
  logo: string;
  social: string;
  id?: string;
  
  //fill id by data rep
  packageSponsor?: PackageSponsorItem;
  tournament?: TournamentItem[];
}

export interface Form {
  key: string;
  label: string;
  type: string;
  selectMultiple?: boolean;
  description?:string;
}
export interface WapperForm {
  key: string;
  label: string;
  type?: string;
  form?: Form[];
}
export interface Param {
  pageSize: number;
  pageIndex: number;
  status?: string;
  search?: string;
}
export interface SizeType {
  label: string;
  value: number;
}
export interface SponsorState {
  packageSponsorItems: PackageSponsorItem[];
  sponsorItems: SponsorItem[];
  dataTabHeaderPackageSponsor: Column[];
  formConfigPackageSponsor: WapperForm[];
  dataTabHeaderSponsor: Column[];
  formConfigSponsor: Form[];
  bodyCUPackageSponsor: BodyCUPackageSponsor;
  pagingParam: Param;
  logoSizeValues: SizeType[];
}

const initialState: SponsorState = {
  packageSponsorItems: [],
  sponsorItems: [],
  pagingParam: {
    pageSize: 1000,
    pageIndex: 1,
  },
  logoSizeValues: [
    {
      label: "Small",
      value: 1,
    },
    {
      label: "Medium",
      value: 2,
    },
    {
      label: "Big",
      value: 3,
    },
  ],
  bodyCUPackageSponsor: {
    name: "",
    speakingSlot: 0,
    isMentionOnYoutube: false,
    isBannerLeaderboard: false,
    isLogoInTrack: false,
    isTwitterAnnouncement: false,
    isHighlightPost: false,
    logoSizeOnWeb: 1,
    logoSizeOnOfficial: 1,
    logoSizeOnMedia: 1,
    isNewsletterEmail: false,
    isMentionedInServices: false,
    isCertificateOfSponsorship: false,
    isBecomeLongterm: false,
    id: "",
  },
  dataTabHeaderPackageSponsor: [
    {
      id: "name",
      label: "Name",
      minWidth: "13%",
    },
    {
      id: "speakingSlot",
      label: "Speaking Slot (min)",
      minWidth: "12%",
    },
    {
      id: "isBannerLeaderboard",
      label: "Banner Leaderboard",
      minWidth: "12%",
    },
    {
      id: "isLogoInTrack",
      label: "Logo In Track",
      minWidth: "13%",
    },
    {
      id: "isHighlightPost",
      label: "Highlight Post",
      minWidth: "13%",
    },
    {
      id: "logoSizeOnWeb",
      label: "Logo Size On Web",
      minWidth: "13%",
    },
    {
      id: "isMentionedInServices",
      label: "Mentioned In Services",
      minWidth: "13%",
    },
    {
      id: "action",
      label: "Action",
      minWidth: "20%",
    },
  ],
  formConfigPackageSponsor: [
    {
      key: "name",
      label: "Name",
      type: "text",
    },
    {
      key: "livestreamOnYoutube",
      label: "Livestream on youtube",
      form: [
        {
          key: "isMentionOnYoutube",
          label: "Mention on youtube",
          type: "checkbox",
          description:'Shout out and mention by MC'
        },
        {
          key: "speakingSlot",
          label: "Speaking slot",
          type: "number",
          description:'Speaking slot'
        },
      ],
    },
    {
      key: "UI/UX",
      label: "UI/UX in game",
      form: [
        {
          key: "isBannerLeaderboard",
          label: "Banner leaderboard",
          type: "checkbox",
          description:'Banner advertisement above leaderboard'
        },
        {
          key: "isLogoInTrack",
          label: "Logo in track",
          type: "checkbox",
          description:'Logo in Race Track'
        },
      ],
    },
    {
      key: "marketing",
      label: "Marketing",
      form: [
        {
          key: "isTwitterAnnouncement",
          label: "Twitter announcement",
          type: "checkbox",
          description:'Twitter announcement about sponsorship'
        },
        {
          key: "isHighlightPost",
          label: "Highlight post",
          type: "checkbox",
          description:'Sponsor Highlight - An intro post about the sponsor'
        },
        {
          key: "isNewsletterEmail",
          label: "Newsletter email",
          type: "checkbox",
          description:'Email newsletter'
        },
        {
          key: "isMentionedInServices",
          label: "Mentioned in services",
          type: "checkbox",
          description:'Name mentioned in PR Services'
        },
        {
          key: "logoSizeOnWeb",
          label: "Logo size on web",
          type: "dropdown",
          description:'Logo on website of event'
        },
        {
          key: "logoSizeOnOfficial",
          label: "Logo size on official",
          type: "dropdown",
          description:'Logo on official poster of the event'
        },
        {
          key: "logoSizeOnMedia",
          label: "Logo size on media",
          type: "dropdown",
          description:'Logo on the cover of RaceFi social media during the period'
        },
      ],
    },
    {
      key: "others",
      label: "Others",
      form: [
        {
          key: "isCertificateOfSponsorship",
          label: "Certificate of sponsorship",
          type: "checkbox",
          description:'NFT Certificate of Sponsorship '
        },
        {
          key: "isBecomeLongterm",
          label: "Become longterm",
          type: "checkbox",
          description:'Become long-term partner of RaceFi '
        },
      ],
    },
  ],
  dataTabHeaderSponsor:[
    {
      id: "name",
      label: "Name",
      minWidth: "20%",
    },
    {
      id: "packageSponsor",
      label: "Package sponsor",
      minWidth: "20%",
    },
    {
      id: "logo",
      label: "Logo",
      minWidth: "20%",
    },
    {
      id: "social",
      label: "Social",
      minWidth: "20%",
    },
    {
      id: "action",
      label: "Action",
      minWidth: "20%",
    },
  ],
  formConfigSponsor:[
    {
      key: "name",
      label: "Name",
      type: "text",
      description:''
    },
    {
      key: "description",
      label: "Description",
      type: "text",
      description:''
    },
    {
      key: "packageSponsorId",
      label: "Package Sponsor",
      type: "dropdown",
      description:''
    },
    {
      key: "tournamentId",
      label: "Tournament",
      type: "dropdownmulti",
      description:''
    },
    {
      key: "logo",
      label: "Logo",
      type: "file",
      description:''
    },
    {
      key: "social",
      label: "Social",
      type: "text",
      description:''
    },
  ]
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackageSponsor.fulfilled, (state, action) => {
        if (action.payload) {
          state.packageSponsorItems = action.payload ?? [];
        }
      })
      .addCase(createPackageSponsor.fulfilled, (state, action) => {
        if (action.payload) {
          state.packageSponsorItems.unshift(action.payload);
        }
      })
      .addCase(getSponsor.fulfilled, (state, action) => {
        if (action.payload) {
          state.sponsorItems = action.payload ?? [];
        }
      })
      .addCase(createSponsor.fulfilled, (state, action) => {
        if (action.payload) {
          state.sponsorItems.unshift(action.payload);
        }
      });
  },
});

export const { fieldChange } = appSlice.actions;

export default appSlice.reducer;
