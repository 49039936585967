import { createSlice, current } from "@reduxjs/toolkit";
import { getGameConfig, putGameConfig } from "./actions";
import { Column } from "components/Table";
import { Form } from "constant/interface";

export interface GameConfigItem {
  _id: string;
  minScheduleFee: number;
  maxScheduleFee: number;
  pauseEconNft: boolean;
  pauseEconSpl: boolean;
  rewardWinnerSchedulePercent: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface putGameConfigParam {
  minScheduleFee: number;
  maxScheduleFee: number;
  pauseEconNft: boolean;
  pauseEconSpl: boolean;
  rewardWinnerSchedulePercent: number;
}

export interface GameConfigState {
  gameConfigItem?: GameConfigItem;
  dataTabHeader: Column[];
  formConfig: Form[];
}

const initialState: GameConfigState = {
  dataTabHeader: [
    {
      id: "authorize",
      label: "Old authorize",
      minWidth: "25%",
      type: "publickey",
    },
    {
      id: "beneficiary",
      label: "Beneficiary",
      minWidth: "25%",
      type: "publickey",
    },
    {
      id: "fee",
      label: "Fee (%)",
      minWidth: "25%",
      type: "BN",
    },
    {
      id: "config",
      label: "Config",
      minWidth: "25%",
      type: "button",
    },
  ],
  formConfig: [
    {
      key: "authorize",
      label: "Old Authorize",
      type: "publickey",
    },
    {
      key: "newAuthorize",
      label: "New Authorize",
      type: "publickey",
    },
    {
      key: "fee",
      label: "Fee",
      type: "BN",
    },
    {
      key: "beneficiary",
      label: "New beneficiary",
      type: "BN",
    },
  ],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGameConfig.fulfilled, (state, action) => {
      if (action.payload) {
        state.gameConfigItem = action.payload ?? [];
      }
    });
  },
});

export const { fieldChange } = appSlice.actions;

export default appSlice.reducer;
