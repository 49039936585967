import React, { memo } from "react";
import { Box, Divider, Stack, SxProps, Typography } from "@mui/material";
import WalletButton from "components/WalletButton";
import Styled from "./index.module.scss";

type HeaderProps = {
  sx?: SxProps;
};

const Header = (props: HeaderProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      minHeight={100}
      width="100%"
      position="fixed"
      zIndex={3}
      sx={props.sx}
      flex={9}
    >
      <Stack
        position="relative"
        width="100%"
        height={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          boxSizing: "border-box",
          background: "rgba(255,255,255,.3)",
        }}
      >
        <Stack direction="row" position="absolute" sx={{ right: 0 }}>
          <Stack
            position="relative"
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{ position: "absolute", zIndex: 2 }}
            >
              {/* <WalletInfo /> */}
              <WalletButton />
            </Stack>
            <Box component="img" src={"/images/login.png"} width={200} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(Header);
