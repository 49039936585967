import React, { memo } from 'react';
import { Stack, Typography } from '@mui/material'


const ReqConnectWallet = () => {
    return (
        <Stack
            width="100%"
            height="100%"
            mt={40}
            justifyContent="center"
            alignItems="center"
        >
            <Typography
                variant="h3"
                sx={{
                    fontSize: 25,
                    fontWeight: "bold",
                    letterSpacing: 0.5,
                    color: "text.primary",
                    textAlign: "center",
                }}
            >
                Please connect your wallet to continue
            </Typography>
            {/* <WalletButton /> */}
        </Stack>
    )
}

export default memo(ReqConnectWallet);
