import * as anchor from "@project-serum/anchor";
import { Program, BN as BigNumber } from "@project-serum/anchor";
import {
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import { EconNft, IDL as EconNftIdl } from "assets/programs/econ_nft";
import { EconSpl, IDL as EconSplIdl } from "assets/programs/econ_spl";
import { BN, Wallet } from "@project-serum/anchor";
import * as SPLToken from "@solana/spl-token";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  ECON_NFT_CONTRACT,
  ECON_SPL_CONTRACT,
  SOLANA_ENDPOINT,
} from "constant";
import { decimalToNumber } from "utils";

const config = Keypair.generate();
export interface ActionPauseParam {
  config: PublicKey;
  isPaused: boolean;
  authority: PublicKey;
  myWallet?: Wallet;
}

export const getMyProgram = (myWallet: Wallet, contractId, idl) => {
  const programId = new anchor.web3.PublicKey(contractId);

  const connection = new Connection(SOLANA_ENDPOINT, "confirmed");

  const provider = new anchor.AnchorProvider(connection, myWallet, {
    preflightCommitment: "recent",
  });

  const program = new anchor.Program(idl, programId, provider);

  return program;
};

export const onActionPauseNft = async (param: ActionPauseParam) => {
  console.log("🚀 onActionPauseNft ~ param", param);
  console.log("🚀xxxx config ~ param", param.config.toBase58());

  try {
    if (!param.authority) return;
    const program = getMyProgram(
      param.myWallet as unknown as Wallet,
      ECON_NFT_CONTRACT,
      EconNftIdl,
    ) as Program<EconNft>;
    console.log("🚀 onActionPauseNft ~ program", program);

    const data = await program.methods
      .setPause(param.isPaused)
      .accounts({
        config: param.config,
        authority: param.authority,
      })
      .rpc();
    console.log("🚀 onActionPauseNft ~ data", data);

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const onActionPauseSpl = async (param: ActionPauseParam) => {
  console.log("🚀 onActionPauseSpl ~ param", param);
  console.log("🚀xxxx config ~ param", param.authority.toBase58());

  try {
    if (!param.authority) return;
    const program = getMyProgram(
      param.myWallet as unknown as Wallet,
      ECON_SPL_CONTRACT,
      EconSplIdl,
    ) as Program<EconSpl>;
    const date = await program.methods
      .setPause(param.isPaused)
      .accounts({
        config: param.config,
        authority: param.authority,
      })
      .rpc();
    return date;
  } catch (err) {
    console.error(err);
  }
};

// export const onGetConfigNft = async (wallet: Wallet) => {
//   try {
//     const program = getMyProgram(
//       wallet,
//       ECON_NFT_CONTRACT,
//       EconNftIdl,
//     ) as anchor.Program<EconNft>;

//     const preConfigNft = await program.account.econNftConfig.all();
//     return preConfigNft;
//   } catch (err) {
//     console.error(err);
//   }
// };

// export const onGetConfigSpl = async (wallet: Wallet) => {
//   try {
//     const program = getMyProgram(
//       wallet,
//       ECON_SPL_CONTRACT,
//       EconSplIdl,
//     ) as anchor.Program<EconSpl>;
//     const preConfigSpl = await program.account.econSplConfig.all();
//     return preConfigSpl;
//   } catch (err) {
//     console.error(err);
//   }
// };
