/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, ReactNode } from 'react';
import {
    Button,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { Column } from 'components/Table';

type TableLayoutProps = {
    dataTabHead: Column[];
    rowChildrent?: ReactNode;

}

const TableLayoutV2 = (props: TableLayoutProps) => {
    const { dataTabHead, rowChildrent } = props;

    return (
        <Paper
            sx={{
                width: "100%",
                overflow: "hidden",
                border: "1px solid #343434",
                borderRadius: 2,
                backgroundColor: "transparent",
            }}
        >
            <TableContainer sx={{}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow sx={{ width: "100%" }}>
                            {dataTabHead.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ width: column.minWidth, fontWeight: "bold" }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowChildrent}
                    </TableBody>
                </Table>
            </TableContainer>

        </Paper>
    )
}

export default memo(TableLayoutV2);
