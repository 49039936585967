import { configureStore } from "@reduxjs/toolkit";
import { AppState } from "store/app";
import appReducer from "store/app/reducer";
import racetrackReducer, { RacetractState } from "store/racetrack/reducer";
import stakingReducer, { StakingState } from "./staking/reducer";
import configMarketReducer, {
  ConfigMarketState,
} from "store/configMarket/reducer";
import specificationReducer, {
  SpecificationState,
} from "./specification/reducer";
import tournamentReducer, { TournamentState } from "./tournament/reducer";
import SponsorReducer, { SponsorState } from "./sponsor/reducer";
import FlashEventReducer, { FlashEventsState } from "./flashevent/reducer";
import GameConfigReducer, { GameConfigState } from "./gameconfig/reducer";
import EconConfigReducer, { EconConfigState } from "./econconfig/reducer";

export interface State {
  app: AppState;
  racetrack: RacetractState;
  staking: StakingState;
  configMarket: ConfigMarketState;
  specification: SpecificationState;
  tournament: TournamentState;
  sponsor: SponsorState;
  flashEvent: FlashEventsState;
  gameConfig: GameConfigState;
  econConfig: EconConfigState;
}

export const store = configureStore({
  reducer: {
    app: appReducer,
    racetrack: racetrackReducer,
    staking: stakingReducer,
    configMarket: configMarketReducer,
    specification: specificationReducer,
    tournament: tournamentReducer,
    sponsor: SponsorReducer,
    flashEvent: FlashEventReducer,
    gameConfig: GameConfigReducer,
    econConfig: EconConfigReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
