import React, { memo, ReactElement, ReactNode } from 'react';
import { Stack, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

type TooltipsLayoutProps = {
    children: ReactElement,
    title: string,
}

const TooltipsLayout = (props: TooltipsLayoutProps) => {

    const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            padding: '15px 10px',
            fontSize: 13,
            backgroundColor: theme.palette.common.black,
        },
    }));
    return (
        <BootstrapTooltip title={props.title} placement="top-start" arrow sx={{color:'red'}}>
            {props.children}
        </BootstrapTooltip>
    )
}

export default memo(TooltipsLayout);
