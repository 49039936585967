import * as anchor from "@project-serum/anchor";
import { BN, Wallet } from "@project-serum/anchor";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
  IDL as MarketplaceProgramIdl, MarketplaceProgram
} from "assets/programs/marketplace_program";
import { MARKET_CONTRACT, SOLANA_ENDPOINT } from "constant";

export interface ParamConfig {
  authorize?: string;
  newAuthorize?: string;
  beneficiary?: string;
  fee?: number;
  wallet?: Wallet;
  marketplaceConfigAccount?: PublicKey;
}

export const getMyProgram = (myWallet: Wallet) => {
  const programId = new anchor.web3.PublicKey(MARKET_CONTRACT);

  const connection = new Connection(SOLANA_ENDPOINT, "confirmed");

  const provider = new anchor.AnchorProvider(connection, myWallet, {
    preflightCommitment: "recent",
  });

  const program = new anchor.Program<MarketplaceProgram>(
    MarketplaceProgramIdl,
    programId,
    provider,
  );

  return program;
};

export const getWalletConfig = async (wallet: Wallet) => {
  try {
    const walletConfigByProgram = await getMyProgram(
      wallet,
    ).account.marketplaceConfig.all();
    return walletConfigByProgram;
  } catch (err) {
    console.error(err);
  }
};

export const onConfigFee = async (paramConfig: ParamConfig) => {
  try {
    if (!paramConfig.wallet) return;
    const old_authorize = new PublicKey(paramConfig.authorize as string);
    const new_authorize = paramConfig.newAuthorize
      ? new PublicKey(paramConfig.newAuthorize as string)
      : new PublicKey(paramConfig.authorize as string);
    const new_beneficiary = new PublicKey(paramConfig.beneficiary as string);
    const new_fee = new BN((Number(paramConfig.fee) * LAMPORTS_PER_SOL) / 100);

    const program = getMyProgram(paramConfig.wallet as Wallet);
    const data = await program.methods
      .updateConfig(new_authorize, new_beneficiary, new_fee)
      .accounts({
        marketplaceConfig: paramConfig.marketplaceConfigAccount,
        authorize: old_authorize,
      })
      .rpc();
    return data;
  } catch (err) {
    console.error(err);
  }
};
