import { createSlice } from "@reduxjs/toolkit";
import { Column } from "components/Table";
import { getMetadata } from "./actions";



export interface MetadataItem {
  collectionId?: string;
  topSpeed?: number;
  acceleration?: number;
  handling?: number;
  classType?: string;
  fuel?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ParamUpdateMetadata {
  topSpeed: number,
  acceleration: number,
  handling: number,
  classType?: string,
  fuel: number,
  collectionId?: string,
}
export interface SpecificationState {
  metadataItems: MetadataItem[];
  dataTabHeader: Column[];
  formConfig: { key: string; label: string }[];
}

const initialState: SpecificationState = {
  metadataItems: [],
  dataTabHeader: [
    {
      id: "name",
      label: "Name",
      minWidth: "15%",
    },
    {
      id: "classType",
      label: "Class",
      minWidth: "15%",
    },
    {
      id: "topSpeed",
      label: "Top Speed",
      minWidth: "15%",
    },
    {
      id: "acceleration",
      label: "Acceleration",
      minWidth: "15%",
    },
    {
      id: "handling",
      label: "Handling",
      minWidth: "15%",
    },
    {
      id: "fuel",
      label: "Fuel",
      minWidth: "15%",
    },
    {
      id: "update",
      label: "Update",
      minWidth: "15%",
    },
  ],
  formConfig: [
    {
      key: "topSpeed",
      label: "Top Speed",
    },
    {
      key: "acceleration",
      label: "Acceleration",
    },
    {
      key: "handling",
      label: "Handling",
    },
    {
      key: "fuel",
      label: "Fuel",
    },
    {
      key: "classType",
      label: "Class Type",
    },
  ],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMetadata.fulfilled, (state, action) => {
      if (action.payload) {
        state.metadataItems = action.payload ?? [];
      }
    });
  },
});

export const { fieldChange } = appSlice.actions;

export default appSlice.reducer;
