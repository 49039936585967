/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
import { Column } from "components/Table";
import { TournamentItem } from "store/tournament";
import {
  createFlashEvents,
  deleteFlashEvents,
  getFlashEvents,
} from "./actions";

export enum FlashEventType {
  Tournament = 1,
  SaleNft,
}
export enum Status {
  INACTIVE = 1,
  ACTIVE = 2,
}
export interface FlashEventsItem {
  id: string;
  eventType: FlashEventType;
  displayTime: Date;
  endTime: Date;
  status: Status;
  banner: string;
  detail: TournamentItem | any;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface BodyCUFlashEvent {
  eventType: FlashEventType;
  displayTime: Date;
  endTime: Date;
  status: Status;
  banner: string;
  detailId: string;
  id?: string;
  detail?: TournamentItem | any;
}

export interface Form {
  key: string;
  label: string;
  type?: string;
  selectMultiple?: boolean;
}
export interface ParamGetFlashEvent {
  pageSize: number;
  pageIndex: number;
  status?: string;
  eventType?: FlashEventType;
  search?: string;
}

export interface FlashEventsState {
  flashEventItems: FlashEventsItem[];
  dataTabHeader: Column[];
  formConfig: Form[];
  pagingParam: ParamGetFlashEvent;
  flashEventTypeValue: formValue[];
  statusValue: formValue[];
}

export interface formValue {
  id: string;
  name: string;
}
const initialState: FlashEventsState = {
  flashEventItems: [],
  pagingParam: {
    pageSize: 1000,
    pageIndex: 1,
  },
  flashEventTypeValue: [
    {
      id: "1",
      name: "Tournament",
    },
    {
      id: "2",
      name: "Sale Nft",
    },
  ],
  statusValue: [
    {
      id: "1",
      name: "In Active",
    },
    {
      id: "2",
      name: "Active",
    },
  ],
  dataTabHeader: [
    {
      id: "name",
      label: "Name",
      minWidth: "15%",
    },
    {
      id: "eventType",
      label: "Event Type",
      minWidth: "15%",
    },
    {
      id: "status",
      label: "Status",
      minWidth: "15%",
    },
    {
      id: "displayTime",
      label: "Display Time",
      minWidth: "15%",
    },
    {
      id: "endTime",
      label: "End Time",
      minWidth: "15%",
    },
    {
      id: "banner",
      label: "Banner",
      minWidth: "15%",
    },
    {
      id: "action",
      label: "Action",
      minWidth: "10%",
    },
  ],
  formConfig: [
    {
      key: "eventType",
      label: "Event Type",
      type: "dropdown",
    },
    {
      key: "status",
      label: "Status",
      type: "dropdown",
    },
    {
      key: "detailId",
      label: "Detail",
      type: "dropdown",
    },
    {
      key: "displayTime",
      label: "Display Time",
      type: "picktime",
    },
    {
      key: "endTime",
      label: "End Time",
      type: "picktime",
    },
    {
      key: "banner",
      label: "Banner",
      type: "file",
    },
  ],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fieldChange(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlashEvents.fulfilled, (state, action) => {
        if (action.payload) {
          state.flashEventItems = action.payload ?? [];
        }
      })
      .addCase(createFlashEvents.fulfilled, (state, action) => {
        if (action.payload) {
          state.flashEventItems.unshift(action.payload);
        }
      });
  },
});

export const { fieldChange } = appSlice.actions;

export default appSlice.reducer;
